.page-node-288 {
	main {
		display: none;
	}
	h1 .card-title-field {
		display:none;
	}
	section.content-hero {
		background:$gainsboro;
	}
}
.directory-title-block {
	margin-top:40px;
	
	h2 {
		font-weight:normal;
		color:$deep_koamaru;
		font-size:2.125em;
		margin-bottom:0;
		text-align:center;
	}
}

.counter-block {
	.views-row {
		width: 100% !important;
	}
	.card-nid {
		margin-bottom:20px;
	}
	a.directory-counter {
		display:block;
		padding:20px;
		text-align:center;
		background:white;
		position: relative;
		
		&:after {
			content:" ";
			background:$pattern-dark-6;
			height:100%;
			width:100%;
			position:absolute;
			opacity:0.3;
			top:0;
			left:0;
			display:block;	
		}
		
		.counter-type:before {
			content:" ";
			height:95px;
			width:100%;
			display:inline-block;
		}
	}
}

.view-display-id-organisation {
	.counter-type:before {
		background: $directory-org no-repeat 50% 50% / 70px 70px;
	}
}
.view-display-id-people {
	.counter-type:before {
		background: $directory_professional no-repeat 50% 50% / 80px 80px;
	}
}
.view-display-id-services {
	.counter-type:before {
		background: $directory_service no-repeat 50% 50% / 70px 70px;
	}
}
.view-display-id-opportunity {
	.counter-type:before {
		background: $directory_opportunity no-repeat 50% 50% / 85px 85px;
	}
}
.view-display-id-products {
	.counter-type:before {
		background: $directory_product no-repeat 50% 50% / 70px 70px;
	}
}
.view-display-id-course {
	.counter-type:before {
		background: $directory_academic no-repeat 50% 50% / 90px 90px;
	}
}
.view-display-id-research {
	.counter-type:before {
		background: $directory_research no-repeat 50% 50% / 76px 70px;
	}
}
.postscript-first .section-row.wrapper-counter-blocks {
	padding: $vert-space $horz-space/2 0; 
}

.postscript-first .section-row .sponsored-orgs {
	@include outer-container($site-max-width);
	h2 {
		text-align:center;
		border-top: 1px solid rgba(85,85,85,0.2);
		padding-top: 40px;
		width: 80%;
		margin:0 auto 30px;
		&:before {
			content:" ";
			height:30px;
			width:30px;
			display:inline-block;
			margin-right:20px;
		}
	}
}

.sponsored-orgs {
	&.sponsored-gold {
		h2:before {
			background: $ico-gold no-repeat scroll 50% 50% / 30px 30px;
		}
	}
	&.sponsored-silver {
		h2:before {
			background: $ico-silver no-repeat scroll 50% 50% / 30px 30px;
		}
	}
	&.sponsored-bronze {
		h2:before {
			background: $ico-bronze no-repeat scroll 50% 50% / 30px 30px;
		}
	}
	&:last-child {
		margin-bottom: 20px;
	}
}

.section-row.section-full-width-image {
	padding:0;
	position: relative;
	.card-full-width-image {
		text-align: center;
		margin-bottom: -6px;
		&:before {
			background:$pattern-dark-6;
			content: "";
			display: block;
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			opacity:0.8;
		}
		.full-width-image { line-height: 0; }
		.full-width-box {
			display:block;
			background: $middle_yellow;
			padding: 30px 20px;
			color:$deep_koamaru;
			bottom: 0;
			position: relative;
			width: 100%;
			left:0px;
			
			@include media($narrow) {
				display: inline-block;
				left: calc(50% - 210px);
				margin: 30px auto 0;
				width: 420px;
			}
			@include media($normal){
			width: 580px;
			left: calc(50% - 290px);

			}
			
			h1 {
				margin-bottom:5px;
			}
			h3 {
				margin:0;
				font-size:1.25em;
			}
		}
		picture + .full-width-box {
			@include media($narrow) {
				position:absolute;				
			}
		}
	}
}