@import "../../../neato/bower_components/neat/app/assets/stylesheets/neat-helpers";
// Neat Overrides
// $column: 90px;
// $gutter: 30px;
// $grid-columns: 12;
// $max-width: em(1088);

// Neat Breakpoints
//$small-screen: em(640);
//$medium-screen: em(640);
//$large-screen: em(860);

//$medium-screen-up: new-breakpoint(min-width $medium-screen 4);
//$large-screen-up: new-breakpoint(min-width $large-screen 8);

$vert-space     : 20px   !default;
$horz-space     : 20px   !default;

$mobile         : 0em    !default;
$mobile-columns : 12     !default;
$narrow         : 45em   !default;
$narrow-columns : 12     !default;
$normal         : 60em   !default;
$normal-columns : 12     !default;
$wide           : 75em   !default;
$wide-columns   : 12     !default;

$site-max-width : 1600px !default; //sometimes 1320px

$wide-screen    : new-breakpoint(max-width 1600px 12) !default; /*1320*/
$normal-screen  : new-breakpoint(max-width 1199px 12) !default;
$narrow-screen  : new-breakpoint(max-width 959px  12) !default;
$mobile-screen  : new-breakpoint(max-width 719px  12) !default;


@mixin section-container {
	@include outer-container;
	@include media($wide-screen)    { max-width: 1600px; } /*alternatively 1320px*/
	@include media($normal-screen)  { max-width: 1199px; }
	@include media($narrow-screen)  { max-width:  959px; }
	@include media($mobile-screen)  { max-width:  719px; }
}

$gutt: 30px;
