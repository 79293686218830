.page-node-592 {
  section#content {
    .content-main {
      .health-az,
      .diamond-links {
        width: 100%;
      }
      @include media($narrow) {
				.health-az,
				.diamond-links {
          width: 50%;
        }
      }
      @include media($normal) {
				.health-az {
          width: 60%;
        }
				.diamond-links {
          width: 40%;
        }
      }			
			
      .diamond-links {
        @include media($narrow) {
          float: left;
          padding: 0 0 0 10px;
        }
				.field-content :last-child { margin-bottom: 0; }
      }
      .health-az {
				@media (max-width:$narrow) {
					@include clearfix;
					margin-bottom: 20px;
				}
        @include media($narrow) {
          float: left;
          padding: 0 10px 0 0;
        }
      }
    }
  }
  .view-alerts.view-id-alerts {
    //padding: 0 10px;
    .card-alert {
      margin-bottom: 10px;
    }
  }
  .section-row-block .block-title {
    text-align: left;
    margin-left: 20px;
  }
}
.health-az {
  .view-display-id-health_az.section-row {
    background: $white;
    box-shadow: $box-shadow;
    padding: 20px 30px;
    padding-bottom: 20px !important;
    float: left;
    .view-header {
      h2 {
				border-bottom: 1px solid $anti_flash_white;
				font-size: 2.125em;
        margin: -20px -30px 20px -30px;
        padding: 20px 30px 20px;
        //margin: 0;
				//padding: 0 0 20px;
      }
      .larger-text {
        font-size: 1.625rem;
        line-height: 2.375rem;
      }
      .card-feed-link {
        float: none;
        margin: 0 0 8px;
        display: inline-block;
        padding: 7px 0;
        border-radius: 2px;
        a:after {
          margin-right: 0;
        }
      }
      .common-header {
        color: $deep_koamaru;
        font-size: 1.375rem;
        line-height: 1.875rem;
        border-bottom: 1px solid $deep_koamaru;
        margin: 0 -10px 5px;
        padding: 8px 10px;
      }
    }
    .view-content {
      .views-row {
        width: 100%;
        border-bottom: 1px solid $anti_flash_white;
        float: left;
        font-size: 1.250rem;
        line-height: 1.750rem;
        padding: 8px 0;
        @include media($narrow) {
          width: 50%;
        }
        &.views-row-5,
        &.views-row-6 {
          border-bottom: none;
        }
        &.views-row-odd {
          @include media($narrow) {
            padding-right: 15px;
          }
        }
        &.card {
          box-shadow: none;
          margin-bottom: 0;
        }
      }
    }
  }
}
