button,
input[type="button"],
input[type="reset"],
input[type="submit"],
#close-country span {
  background-color: $button-bg;
  border: $button-border;
  border-radius: $button-border-radius;
  border: none;
  color: $button-text;
  cursor: pointer;
  display: inline-block;
  font: $button-font;
  padding: 0.75rem 1rem;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:hover,
  &:focus {
    background-color: $button-hover-bg;
    color:  $button-hover-text;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

a.button,
.button a,
a.alert.button { 
	@include button-link-default();
  border-radius: $button-border-radius;
  border: none;
  cursor: pointer;
  display: inline-block;
  font: $button-link-font;
  padding: 0.75em 1em;
  text-decoration: none;
  user-select: none;
  vertical-align: middle;
  //white-space: nowrap;
}

.view-id-alerts .alert.button {
	background-color: $sunset_orange;
	
	&:hover,
  	&:focus {
    background-color: $true_blue;
    color:  $button-hover-text;
  }
}