// Font styles for the following can be configured in var-typography

// /* HEADER */ //
$header-bg-color                   : none;

$header-menu-link-color            : $primary;
$header-menu-link-hover            : $black;

$header-search-bg-color      			 : $true_blue;
$header-search-field-bg-color      : white;
$header-search-field-text-color    : $primary;
$header-search-field-box-shadow    : none;
$header-search-field-border        : none;
$header-search-field-border-radius : 0;


// /* MAIN MENU */ //
$nav-desktop-trigger-width		: 1000px;
$nav-wrapper-bg-color					: $sapphire_blue;
$nav-base-bg-color        		: $sapphire_blue;
$nav-base-text-color     		 	: white;
$nav-base-padding        			: 0.625rem 0.875rem;
$nav-base-hover-padding   		: 0.625rem 0.875rem;
$nav-sub-base-padding					: 0.625rem 1rem;
$nav-sub-base-hover-padding		: 0.625rem 1rem;

$nav-base-padding-smaller 				: 0.5rem 0.675rem; //for use at awkward breakpoints so the menu still fits on one line
$nav-base-hover-padding-smaller 	: 0.5rem 0.675rem;

$nav-active-bg-color      		: $true_blue;
$nav-active-text-color    		: white;
$nav-active-border-radius 		: 2px 2px 0 0;
$nav-expanded-bg-color				: $ghost_white;
$nav-expanded-text-color			: $sunset_orange;

$nav-hover-bg-color       		: $sunset_orange;
$nav-hover-text-color     		: $white;
$nav-hover-border	  	    		: none;

$nav-highlight-bg-color   		: $true_blue;
$nav-highlight-text-color 		: white;
$nav-highlight-border	    		: none;

$nav-sub-bg-color         		: white;
$nav-sub-hover-bg-color   		: $sunset_orange;
$nav-sub-text-color       		: $primary;
$nav-sub-hover-text-color   	: white;
$nav-sub-box-shadow      			: $box-shadow;
$nav-sub-border          			: none;
$nav-sub-border-radius  		  : none;

$nav-mob-active-bg-color      			: $true_blue;

$nav-mob-1st-bg-color     					: $white;
$nav-mob-1st-highlight-bg-color     : $sunset_orange;
$nav-mob-1st-highlight-text-color   : $white;

$nav-mob-2nd-bg-color     					:  $ghost_white;
$nav-mob-2nd-highlight-bg-color     : $anti_flash_white;
$nav-mob-2nd-highlight-text-color   : $sunset_orange;

$nav-mob-3rd-bg-color     					: $white;
$nav-mob-3rd-highlight-bg-color     : $sunset_orange;
$nav-mob-3rd-highlight-text-color   : $white;

$nav-mob-4rd-bg-color     					: white;
$nav-mob-4rd-highlight-bg-color     : $timberwolf;
$nav-mob-4rd-highlight-text-color   : $primary;

$nav-mob-border											: 3px solid $true_blue;
$nav-mob-expanded-border						: 1px solid $light_grey;
$nav-mob-border-hover								: none;
$nav-mob-shadow											: none;

$nav-button-bg-color						: $deep_koamaru;
$nav-button-hover-bg-color			: $true_blue;
$nav-button-height      				: 80px;
$nav-button-width      					: 80px;
$nav-button-bar-bg-color      	: $deep_koamaru;
$nav-button-bar-hover-bg-color  : white;
$nav-button-bar-height      		: 6px;
$nav-button-bar-width      			: 40px;
$nav-button-bar-border-radius 	: 8px;

// /* BREADCRUMBS */ //
$breadcrumb-bg-color     : $ghost_white;
$breadcrumb-action-color : $sapphire_blue;
$breadcrumb-hover-color  : $sunset_orange;
$breadcrumb-active-color : $primary;
$breadcrumb-div-color    : $primary;



// /* CARDS */ //
// /* --- CARD DEFAULT SETTINGS */
$card-shadow                   : $box-shadow;
$card-border                   : $septenary-border; // moved to card-text
$card-border-radius            : 0;
$card-text-padding             : $vert-space $horz-space !default;
$card-margin-bottom            : $vert-space !default;
$card-transition             	 : background-color 300ms linear 0s;


// /* --- EVENT DISPLAY DATES
$card-date-bg-color            : none; //lighten($platinum,10%);
$card-date-border              : none; //1px solid $pale_grey
$card-date-border-radius       : 0;
$card-date-text-color          : $secondary;
$card-date-font                : 1em/1 $base-font-family;


// /* --- HOVER STYLES: SAME FOR ALL CARDS */
$card-hover-bg-color           : white;
$card-hover-border             : $senary-border; //moved to card-text
$card-hover-shadow             : $box-shadow;
$card-action-hover             : $action-hover;; //colour of card title text when you hover over it
$card-text-hover-color         : $secondary; //colour of regular card text when you hover over it
$card-date-hover-text-color    : $secondary; //lighten($grey,6%);
$card-date-hover-bg-color      : none; // allows display dates (e.g. events) to have different hover background from the rest of the card

/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- un/comment the appropriate line in base/_cards.scss as appropriate
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss
*/
$card-text-hover-bg                 : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg    : $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color : white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color  : white; // alternative background for summary text revealed on hover


// /* --- CARD-LIGHT - light coloured background */
$card-light-bg-color           : white;
$card-light-border-color       : transparent;
$card-light-title-color        : $sapphire_blue;
$card-light-action-default     : $black;
$card-light-action-hover       : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color         : $secondary;;
$card-light-subtitle-color     : $secondary;

// /* --- CARD-MEDIUM - medium coloured background */
$card-medium-bg-color          : $anti_flash_white;
$card-medium-border-color      : transparent;
$card-medium-title-color       : $sapphire_blue;
$card-medium-action-default    : $black;
$card-medium-action-hover      : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color        : $secondary;
$card-medium-subtitle-color    : $secondary;

// /* --- CARD-DARK - dark coloured background */
$card-dark-bg-color            : $anti_flash_white;
$card-dark-border-color        : transparent;
$card-dark-title-color         : $sapphire_blue;
$card-dark-action-default      : $black;
$card-dark-action-hover        : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-dark-text-color          : $secondary;
$card-dark-subtitle-color      : $secondary;

// /* --- CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color       : $anti_flash_white;
$card-highlight-border-color   : transparent;
$card-highlight-title-color    : $sapphire_blue;
$card-highlight-action-default : $black;
$card-highlight-action-hover   : $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-highlight-text-color     : $secondary;
$card-highlight-subtitle-color : $secondary;


// /* --- CARD-MORE-LINKS */
$card-more-link-default-bg          : $anti_flash_white $pattern-dark-4;
$card-more-link-default-color       : $primary;
$card-more-link-default-icon        : $arrow-grey-right no-repeat 50% 50% / 16px auto;
$card-more-link-default-hover-bg    : $anti_flash_white $pattern-dark-4;
$card-more-link-default-hover-color : $sunset_orange;
$card-more-link-default-hover-icon  : $arrow-rose-right no-repeat 50% 50%;

$card-more-link-alt-bg              : $grey;
$card-more-link-alt-color           : white;
$card-more-link-alt-icon            : $arrow-white-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-hover-bg        : $action-hover;
$card-more-link-alt-hover-color     : white;
$card-more-link-alt-hover-icon      : $arrow-white-right no-repeat 100% 100% / 14px auto;



// /* --- CARD-FEED-LINKS */
$card-feed-link-default-bg          : $sunset_orange; // background for the entire link
$card-feed-link-default-color       : white;
$card-feed-link-default-icon        : $arrow-white-right no-repeat scroll 50% 50% / 10px 20px; //background for the default :after component
$card-feed-link-default-hover-bg    : $true_blue;
$card-feed-link-default-hover-color : white;
$card-feed-link-default-hover-icon  : $arrow-white-right no-repeat scroll 50% 50% / 10px 20px;

$card-feed-link-alt-bg              : none; // background for the entire link
$card-feed-link-alt-color           : $white;
$card-feed-link-alt-icon            : $white $arrow-blue-right no-repeat scroll 50% 50% / 7px 10px; //background for the alternative :after component
$card-feed-link-alt-hover-bg        : none;
$card-feed-link-alt-hover-color     : $action-hover;
$card-feed-link-alt-hover-icon      : $action-hover $arrow-white-right no-repeat scroll 50% 50% / 7px 10px;


// /* --- BUTTON LINKS   */
$button-link-default-bg                 : $action-default;
$button-link-default-border-color       : $action-default;
$button-link-default-color              : white;
$button-link-default-icon               : none;
$button-link-default-hover-bg           : $action-hover;
$button-link-default-hover-border-color : $action-hover;
$button-link-default-hover-color        : none;
$button-link-default-hover-icon         : none;

$button-link-alt-bg                     : $grey;
$button-link-alt-border-color           : $grey;
$button-link-alt-color                  : white;
$button-link-alt-icon                   : none;
$button-link-alt-hover-bg               : $action-hover;
$button-link-alt-hover-border-color     : $action-hover;
$button-link-alt-hover-color            : white;
$button-link-alt-hover-icon             : none;




// /* MAIN PAGE */ //
$hero-bg              : $gainsboro;  // /* Should not be the same as $main-page-bg or $palette-default-bg-color for banded sites */
$main-page-bg         : $anti_flash_white;
$section-main-padding : $vert-space*1.5 0 !default;




// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg              				: $white; // used on slide and galler views
$slick-default-controls-bg         				: none; // used on twitter views etc
$slick-default-prev-bg             				: $arrow-red-left no-repeat scroll 50% 50% / 12px 23px;
$slick-default-prev-hover-bg      				: $arrow-blue-left no-repeat scroll 50% 50% / 12px 23px;
$slick-default-next-bg            				: $arrow-red-right no-repeat scroll 50% 50% / 12px 23px;
$slick-default-next-hover-bg      				: $arrow-blue-right no-repeat scroll 50% 50% / 12px 23px;
$slick-default-dot-border         				: 2px solid $sunset_orange;
$slick-default-dot-hover-border    				: 2px solid $deep_koamaru;
$slick-default-dot-active-bg-color 				: $sunset_orange;
$slick-default-dot-active-hover-bg-color 	: $deep_koamaru;


$slick-alt-1-nav-bg                				: rgba(255,255,255,0.25); // used on slide and galler views
$slick-alt-1-controls-bg           				: none; // used on twitter views etc
$slick-alt-1-prev-bg               				: white $arrow-grey-left no-repeat scroll 50% 50%;
$slick-alt-1-prev-hover-bg         				: $japanese_indigo $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-1-next-bg               				: white $arrow-grey-right no-repeat scroll 50% 50%;
$slick-alt-1-next-hover-bg         				: $japanese_indigo $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-1-dot-default-border    				: 2px solid white;
$slick-alt-1-dot-hover-border      				: 2px solid white;
$slick-alt-1-dot-active-bg-color   				: white;
$slick-alt-1-dot-active-hover-bg-color 		: white;


$slick-alt-2-nav-bg                 			: $pale_grey; // used on slide and galler views
$slick-alt-2-controls-bg         					: none; // used on twitter views etc
$slick-alt-2-prev-bg               				: $faded_blue $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg         				: $dark_lavender $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-next-bg               				: $faded_blue $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg         				: $dark_lavender $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-dot-default-border    				: 2px solid $faded_blue;
$slick-alt-2-dot-hover-border      				: 2px solid $faded_blue;
$slick-alt-2-dot-active-bg-color   				: $faded_blue;
$slick-alt-2-dot-active-hover-bg-color 		: $faded_blue;


// /* SLIDES - card-slide-description */ //
$slide-text-bg-color       : rgba(255, 255, 255, 0.7) ;
$slide-text-color          : $primary;
$slide-text-font           : 1.375rem/1.45 $title-font-family;



// /* SIDEBAR Container styles */

$sidebar-second-bg       : none;
$sidebar-second-border   : none;
$sidebar-second-margin   : 0;
$sidebar-second-padding  : 0;

$side-row-bg                   	: $ghost_white;
$side-row-border               	: 1px solid transparent transparent blue transparent;
$side-row-border-radius        	: 0;
$side-row-box-shadow 						: $box-shadow;
$side-row-padding              	: $vert-space $horz-space;
$side-row-inner-mobile-padding 	: 0;
$side-row-inner-padding        	: 0;
$side-row-margin               	: 0 0 $vert-space*1.25;



/* SIDEBAR CARDS */
@mixin palette-sidebar-cards {
	@include card-medium();
}
@mixin palette-sidebar-card-more-link {
	@include card-more-link-default();
}
@mixin palette-sidebar-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-sidebar-button-link {
	@include button-link-alt();
}
@mixin palette-sidebar-slick-controls {
	@include slick-controls-default();
}

$card-sidebar-border        : none;
$card-sidebar-border-radius : 0;
$card-sidebar-shadow        : none;
$card-sidebar-text-padding  : $vert-space $horz-space !default;
$card-sidebar-margin-bottom : $vert-space !default;



// /* SECTION/NUGGET ROWS */ /
$section-row-padding          : $vert-space 0;
$section-inner-mobile-padding : 0 $horz-space/2;
$section-inner-padding        : 0 $horz-space;

// /*Default sets the card colours for main and additional also */
$palette-default-bg-color      : $anti_flash_white;
$palette-default-title-color   : $deep_koamaru; //$primary;
$palette-default-text-color    : $primary;
$palette-default-link-color	   : $action-default;
$palette-default-link-hover    : $action-hover;

//
// --- Set the card style for the DEFAULT band by replacing the card-[mixin] in the mixin below
//
@mixin palette-default-cards {
	@include card-light();
}
@mixin palette-default-card-more-link {
	@include card-more-link-default();
}
@mixin palette-default-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-default-button-link {
	@include button-link-default();
}
@mixin palette-default-slick-controls {
	@include slick-controls-default();
}


$palette-alt-1-bg-color        : $palette-default-bg-color;
$palette-alt-1-title-color     : $palette-default-title-color;
$palette-alt-1-text-color      : $palette-default-text-color;
$palette-alt-1-link-color      : $palette-default-link-color;
$palette-alt-1-link-hover      : $palette-default-link-hover;

//
// --- Set the card style for the ALT-1 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-1-cards {
	@include card-light();
}
@mixin palette-alt-1-card-more-link {
	@include card-more-link-default();
}
@mixin palette-alt-1-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-1-button-link {
	@include button-link-default();
}
@mixin palette-alt-1-slick-controls {
	@include slick-controls-default();
}


$palette-alt-2-bg-color        : $palette-default-bg-color;
$palette-alt-2-title-color     : $palette-default-title-color;
$palette-alt-2-text-color      : $palette-default-text-color;
$palette-alt-2-link-color      : $palette-default-link-color;
$palette-alt-2-link-hover      : $palette-default-link-hover;

//
// --- Set the card style for the ALT-2 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-2-cards {
	//@include card-medium();
	@include card-light();
}
@mixin palette-alt-2-card-more-link {
	@include card-more-link-default();
}
@mixin palette-alt-2-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-2-button-link {
	@include button-link-default();
}
@mixin palette-alt-2-slick-controls {
	@include slick-controls-default();
}

$palette-highlight-bg-color       : $palette-default-bg-color;
$palette-highlight-title-color    : $palette-default-title-color;
$palette-highlight-text-color     : $palette-default-text-color;
$palette-highlight-link-color     : $palette-default-link-color;
$palette-highlight-link-hover     : $palette-default-link-hover;


//
// --- Set the card style for the HIGHLIGHT band by replacing the card-[mixin] in the mixin below
//
@mixin palette-highlight-cards {
	@include card-light();
}
@mixin palette-highlight-card-more-link {
	//@include card-more-link-alt();
	@include card-more-link-default();
}
@mixin palette-highlight-card-feed-link {
	//@include card-feed-link-alt();
	@include card-feed-link-default();
}
@mixin palette-highlight-button-link {
	//@include button-link-alt();
	@include button-link-default();
}
@mixin palette-highlight-slick-controls {
	//@include slick-controls-alt-1();
	@include slick-controls-default();
}

$palette-alt-3-bg-color        : $palette-default-bg-color;
$palette-alt-3-title-color     : $palette-default-title-color;
$palette-alt-3-text-color      : $palette-default-text-color;
$palette-alt-3-link-color      : $palette-default-link-color;
$palette-alt-3-link-hover      : $palette-default-link-hover;


//
// --- Set the card style for the ALT-3 band by replacing the card-[mixin] in the mixin below
//
@mixin palette-alt-3-cards {
	//@include card-highlight();
	@include card-light();
}
@mixin palette-alt-3-card-more-link {
	@include card-more-link-default();
}
@mixin palette-alt-3-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-3-button-link {
	@include button-link-default();
}
@mixin palette-alt-3-slick-controls {
	@include slick-controls-default();
}



// /* PRIMARY TABS */ //
$tab-active-bg-color     : $main-page-bg;
$tab-active-text-color   : $primary;
$tab-active-border       : $primary-border;
$tab-inactive-bg-color   : $base-background-color;
$tab-inactive-text-color : $primary;
$tab-hover-bg-color      : $nav-hover-bg-color;
$tab-hover-text-color    : $nav-hover-text-color;
$tab-border              : 1px solid $nav-highlight-bg-color;
$tab-border-bottom       : $primary-border;





// /* SEARCH */ //
$search-card-bg-color : white;


// /* HIGHCHARTS */ //
$highcharts-font-family      : 'Georgia', serif !default;
$highcharts-container-margin : $vert-space*1.5 0 !default;


// /* FOOTER */ //
$footer-first-bg           : $charleston_green $pattern-light-6;
$footer-first-text         : white;
$footer-first-action       : $sunset_orange;
$footer-first-action-hover : $pale_grey;
$footer-first-border       : none;

//
// --- Set the card style for FOOTER-FIRST by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-first-cards {
	@include card-light();
}
@mixin palette-footer-first-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-first-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-first-slick-controls {
	@include slick-controls-alt-2();
}


$footer-second-bg           : $black;
$footer-second-text         : white;
$footer-second-action       : white;
$footer-second-action-hover : $pale_grey;

//
// --- Set the card style for FOOTER-SECOND by replacing the card-[mixin] in the mixin below
//
@mixin palette-footer-second-cards {
	@include card-light();
}
@mixin palette-footer-second-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-second-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-second-slick-controls {
	@include slick-controls-alt-2();
}

// /* FOOTER CARD-LINKS  -- Follow . social media icons */
$card-links-action-bg            : white;
$card-links-border-radius        : 0;
$card-links-width                : 60px;
$card-links-height               : 60px;
$card-links-hover-opacity        : 0.8;
$card-links-hover-border-radius  : 0;
$card-links-hover-bg             : $pearl_aqua;


$footer-third-bg            : white;
$footer-third-text          : $primary;
$footer-third-action        : $true_blue;
$footer-third-action-hover  : $action-hover;

$footer-prefix-bg            : $gainsboro;
$footer-prefix-text          : $primary;
$footer-prefix-action        : $true_blue;
$footer-prefix-action-hover  : $action-hover;
