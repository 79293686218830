.node-type-alert {
  .view-node-alert.view-display-id-updates.expand-list {
    .views-row {
      margin: 0 -30px;
    }
  }
}
.view-node-alert.view-display-id-updates.expand-list {
  .card-description-et {
    display: none;
    padding: 0 30px;
  }
}
.card-alert-status {
	color: $nickel;
	font: 0.8125em/1.46153846153846 $title-font-family;
	margin: $vert-space/2 0 $vert-space/2;
	text-transform: uppercase;
}