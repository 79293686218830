.node-type-indicator {
  .prefix-wrapper {
    min-height: auto;
    .prefix {
      max-width: 1600px;
      margin-left: auto;
      margin-right: auto;
      .view-display-id-title {
        padding-bottom: 0;
        @include media($narrow) {
          padding-bottom: 13px;
        }
      }
      .block {
        display: inline-block;
        padding:20px 0;
        &.indicator-list {
          width:100%;
          padding:0 0 20px 20px;
          @media (min-width:960px) {
            width:auto;
          }
        }
        .view-indicator-node.view-display-id-title {
          padding-top: 30px;
        }
        .view-id-indicator_list.view-display-id-list {
          //padding: 32px 0 13px;
          padding:0;
          margin-right: 41px;
          @media (min-width:960px) {
            padding: 0 15px 13px;
          }
          select {
            background: $arrow-grey-down no-repeat 95% 50% / 22px 12px white;
            width:100%;
          }
          .chosen-container.chosen-container-single {
            display: block;
            .chosen-single {
              border: 1px solid $true_blue;
              &:first-child:before {
                background: $arrow-white-down no-repeat 50% 50% / 22px 12px $true_blue;
                border-left: none;
              }
            }
            .chosen-drop .chosen-results li:first-child {
              display: none;
            }
            .chosen-search {
              display: block;
            }
          }
        }
      }
    }
    .view-display-id-title {
      min-height: auto;
      padding-left: 20px;
    }
  }
  main {
    display: block;
  }
  article h1 .card-title-field {
    display: none;
  }
  .container-wrapper {
    background: $white;
    // padding: 30px;
    box-shadow: $box-shadow;
    position: relative;
    .container-header {
      border-bottom: 1px solid $pale_grey_two;
      min-height:71px;
      #metric-select {
        padding: 14px 30px 14px 30px;
        @media (min-width:498px) and (max-width:959px) {
          padding: 82px 30px 14px 30px;
        }
      }
      .csv-dl {
        min-width: 200px;
        border-bottom: 1px solid $sapphire_blue;
        width:100%;
        position: inherit;
         @media(min-width:498px) {
          position: absolute;
        }
        @media(min-width:960px) {
          //float: right;
          border-left: 1px solid $sapphire_blue;
          border-bottom: none;
          right:0;
          width:auto;
        }
        
        .view-indicator-export {
          .view-content {
            display: none;
          }
          .feed-icon a {
            display: block;
            background: $icon-dl no-repeat scroll 90% 50% / 36px 34px;
            height: 71px;
            padding: 10px 70px 10px 20px;
            text-align: center;
            font-weight: 700;
          }
        }
      }
    }
    .map-chart-container {
      padding: 30px;
      .chart-wrapper {
        @include media($normal) {
          width:49.5%;
          display:inline-block;
        }
        &::last-child {
          @include clearfix;
        }
      }
    }
    .form-item {
      display: inline;
      float: left;
      margin-right: 20px;
    }
    #close-country {
      position: absolute;
      top: 285px;
      z-index: 1;
      @include media($narrow) {
        top: 105px;
      }
    }
  }
  #metric-select {
    span.label {
      float: left;
      margin: 10px 16px 0 0;
      display: none;
    }
    select {
      background: $arrow-grey-down no-repeat 95% 50% / 22px 12px white;
      margin-bottom: 0;
      border-radius: 2px;
      &:hover {
        cursor: pointer;
      }
    }
    .chosen-container {
      margin-top:10px;
    }
    @media (min-width:960px) {
      .chosen-container {
        width:auto!important;
        position: absolute;
        margin-top:0;
      }
    }
  }
  #slider-year {
    #slider-handle {
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border: 2px solid $true_blue;
    }
  }
  .highcharts-root *, {
    font-family: $base-font-family !important;
  }
  .highcharts-title {
    font-family: $title-font-family !important;
  }
  .highcharts-map-navigation {
    text {
      transform: translateY(-9px);
      fill: $true_blue !important;
    }
    &:hover text {
      fill: $sunset_orange !important;
    }
  }
  .highcharts-xaxis .highcharts-axis-line {
    transform: translateX(-35px) scaleX(1.07);
  }
  .highcharts-yaxis .highcharts-axis-line {
    transform: translateY(-10px) scaleY(1.1);
  }
}


// #field-metrics-select { width:100%; }
.high-container { 
  height: 420px; 
  width: 100%;
  @include media($normal) {
    height: 540px;
  }
}
#container-chart { 
  @include media($normal) {
    margin-top: 60px; 
  }
}
#slider-year, #slider-scale { 
  margin-left: 88px;
  &:hover {
    cursor: pointer;
  }
}
#slider-scale {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  margin-bottom:$vert_space*2;
  @include media($narrow) {
    margin-bottom:0;
  }
  .slider-scale-year {
  	font-size:0.75rem;
  	font-weight:700;
    width:0;
    left:-0.75rem;
    position:relative;
    transform:rotate(270deg) translate3d(-1.5rem,0.75rem,0);
    @include media($narrow) {
      transform:rotate(-40deg) translate3d(0,0,0);
      padding-top:20px;
    }
    &.empty {
      font-weight:500;
      color:lighten($primary,50%);
    }
  }
}
#slider-handle {
  text-indent: -999em;
  // overflow: hidden;
}

.high-container .highcharts-container { margin-top: 0; }
#metric-name { text-align: center; }
#container-chart.high-container { height: 495px; }
