/* FAQ COLLAPSE/EXPAND STYLES */
.view-faqs {
	margin-bottom: 20px;
}

.card-qa-answer {
	display: none;
	//padding: 16px 30px;
	padding: 20px 30px 20px 30px;
	box-sizing: border-box;
	background: $white;
	border-top: 1px solid $anti_flash_white;

	:last-child {
		margin-bottom: 0;
	}
}

.expand-main {
    margin-bottom: 1em;
	.card-qa-question {
		padding: 20px 60px 20px 30px;
		line-height:39px;
	}
}

.node-type-health-condition .node-expand-wrapper {
	margin-top:0;
}
.node-expand-wrapper {
	//margin-top:30px;
	.faq-question {
		background-color: white;
		h2.card-qa-question {
			color:$deep_koamaru;
			font-size:2.125em;
			// padding:20px;
		}
	}
}

.card-qa-question {
	font: 500 1.375rem $title-font-family;
	background-color:white;
	text-decoration: none;
	color: $sapphire_blue;
	padding: 16px 60px 16px 30px;
	line-height: 30px;
	display: block;
	//font-size: 1.375rem;
	border-top: 1px solid $anti_flash_white;
	margin-bottom: -1px;
	cursor: pointer;
	box-sizing: border-box;
	background: $ico-plus-blue no-repeat scroll 95% 50% / 30px 30px white;
	@include media($narrow) {
		background: $ico-plus-blue no-repeat scroll 98% 50% / 30px 30px white;
	}

	&.active {
		//font-weight: 700;
		background: $ico-minus-blue no-repeat scroll 95% 50% / 30px 30px white;
		@include media($narrow) {
			background: $ico-minus-blue no-repeat scroll 98% 50% / 30px 30px white;
		}
	}
}

.view-organisation-node.view-id-organisation_node.view-display-id-excellence,
.view-organisation-node.view-id-organisation_node.view-display-id-healthcare,
.view-organisation-node.view-id-organisation_node.view-display-id-research,
.view-organisation-node.view-id-organisation_node.view-display-id-opps,
.view-organisation-node.view-id-organisation_node.view-display-id-ethics,
.view-organisation-node.view-id-organisation_node.view-display-id-products,
.view-organisation-node.view-id-organisation_node.view-display-id-programmes {
	.expand-inner {
		display: none;
		.card-qa-answer {
			display: block;
		}
	}
}

.expand-list {
	margin-bottom:1em;
	.card-qa-answer {
		padding: 10px 30px 10px 30px;
	}
	h3 {
		font-size: 1.250rem;
	}
	.expand-inner {
		@include media($narrow) {
			.views-row {
				width: 50%;
	  			display: inline-block;
	  			border-top: 1px solid $anti_flash_white;
	  			// background: $ghost_white;
				&.views-row-odd {
					border-right: 1px solid $anti_flash_white;
				}
				&.views-row-first.views-row-last {
					width: 100%;
				}
			}
		}
		.directory-tag {
			float: none;
		}
	}
	.more-link {
		border-top: 1px solid $anti_flash_white;
		background: $ghost_white;
		font-weight: 600;
		a {
			display: block;
			padding: 10px;
		}
	}
}

.view-id-organisation_node.view-display-id-about,
.view-id-health_condition_node.view-display-id-overview { 
	.card-qa-answer {
    	display: block;
	}
}


.node-type-country .view-display-id-bg {
	.card-qa-answer {
		display: block;
	}
}
