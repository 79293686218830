#breadcrumb {
	background:$breadcrumb-bg-color;
	clear: both;
	padding:$vert-space/4 0;
	box-shadow: 0 3px 4px -2px $box-shadow-rgba;
	position: relative;
	
//	@media(max-width:$narrow) {
//		padding:$vert-space/4 0;
//		margin-top: 5px;
//	}
	
	.breadcrumb {
		color:$breadcrumb-active-color;
		display:inline-block;
		font:$breadcrumb-font;
		line-height: 1.5;
		margin:$vert-space/4 0;
		padding:0 $horz-space/2;
		
		@media(min-width:$narrow) {
			padding:0 $horz-space;
		}
		
		span {
			//font-size:0.875rem;
      
      a.active:after {
        display:none;
      }
			
			&:after {
				color:$breadcrumb-div-color;
				content:"";
				margin-left:$horz-space/2;
				margin-right:$horz-space/2;
				background: $arrow-grey-right  no-repeat scroll 100% 50% / 12px 14px transparent;
				height: 14px;
				width: 12px;
				display: inline-block;
				margin-bottom: -2px;
			}
		
			a {
				background:none;
				border:none;
				color:$breadcrumb-action-color;
				//background:$div-breadcrumbs no-repeat 100% 50%;
				
				&:hover {
					color:$breadcrumb-hover-color;
				}
			}
			
			&.first a {
				padding-left: 0;
			}
			
			&.last {
				//padding-left: 15px;
				
				&:after {
					content:normal;
				}
			}
		}
	}
}
