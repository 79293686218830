.has-landing-block {
	.card-original picture {		
		box-shadow: $box-shadow;
		display: inline-block;
	}
	
	.card-title-landing-block {
		text-align: center;
		margin: 0 10px;
		
		h2 {
			margin-bottom: 0;
			background: $pattern-dark-6 $action-alt-default;
			display: inline-block;
			box-sizing: content-box;
			padding: 20px 30px;
			color: white;
			font-size: 1.875rem;
		}
	}
		
	.card-body-landing-block {
		background-color: white;
		padding: 40px;
		box-shadow: $box-shadow;

		a.button {
			padding: 10px 40px 10px 20px;
			border: $tertiary-border;
			color: $action-alt-hover;
			background: $arrow-blue-right calc(100% - 20px) 50% / 10px 20px no-repeat white;

			&:hover {
				border: $quaternary-border;
				color: $action-alt-default;
				background: $arrow-red-right calc(100% - 20px) 50% / 10px 20px no-repeat white;
			}
		}
		
		:last-child { margin-bottom: 0; }
	}
	
	.card-title-landing-block + .card-body-landing-block { margin-top: -20px; }
	
	&.has-landing-block-img .section-inner {
		.card-original {
			text-align: center;
			margin-bottom: -60px;
		}
		.card-original + .card-body-landing-block {
			margin-top: 30px;
		}		
	}
	
	@media (min-width:$narrow) {
		//width: calc(100%/12 * 10);
		margin-left: auto;
		margin-right: auto;
		display: block;
		position: relative;
		
		&.has-landing-block-img {
			.section-inner {
				.card-original {
					float:left;
					max-width: calc(40% - 0.625rem);
					margin: 0 40px 0px 0;		
				}
				.card-title-landing-block {
					margin: 0;
				}
				.card-body-landing-block {
					margin-left: calc(40% - 50px);
					padding: 40px 40px 40px 80px;
				}
			}

			&.img-right .section-inner {
				.card-original {
					float:right;
					margin: 0 0 0px 40px;
				}
				.card-body-landing-block {
					margin-right: calc(40% - 50px);
					margin-left: 0; 
					padding: 40px 80px 40px 40px;
				}
				.card-title-landing-block {
					margin: 0 0 0 40px;
				}
			}
		
			.card-title-landing-block {
				h2 {		
					max-width: calc(60% - 110px);
				}
			}
		}
		
		.card-title-landing-block {
			margin: 0 0 0 40px;
			text-align: left;
		}
	}
}

.has-landing-block.limited-text-width {
	@media (min-width:$narrow) {
		width: calc(100%/12 * 10);
	}
}



body .one-sidebar,
body .two-sidebars {
	.has-landing-block {
		@media (min-width:$narrow) {
			width: 100%;
		}
	}
}