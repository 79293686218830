.item-list  ul.pager {
	border-top: $primary-border;
	position: relative;
	@media screen and (max-width: $narrow) {
		font-size: 0.750em;
	}
	a {
		color: $secondary;
		font-weight: 600;
		padding:0.25em 0.5em;
		
		&:hover {
			background:$action-hover;
			color:#FFFFFF;
		}
	}
	li.pager-next {
		float: right;
		position: absolute;
		right: 55px;
		@media screen and (max-width: $narrow) {
			right: 35px;
		}
		a {
			text-indent: -9999px;
			overflow: hidden;
			display: block;
			background: $arrow-rose-right no-repeat scroll 50% 50%/13px 24px;
			padding: 0 24px 0 0;
			&:hover {
				background: $arrow-blue-right no-repeat scroll 50% 50%/13px 24px;
			}
		}
	}
	li.pager-last {
		float: right;
		a {
			text-indent: -9999px;
			overflow: hidden;
			display: block;
			background: $arrow-rose-last no-repeat scroll 50% 50%/26px 24px;
			padding: 0 24px 0 0;
			&:hover {
				background: $arrow-blue-last no-repeat scroll 50% 50%/26px 24px;
			}
		}
	}
	li.pager-previous {
		float: left;
		position: absolute;
		left: 55px;
		@media screen and (max-width: $narrow) {
			left: 35px;
		}
		a {
			text-indent: -9999px;
			overflow: hidden;
			display: block;
			background: $arrow-rose-left no-repeat scroll 50% 50%/13px 24px;
			padding: 0 20px 0 0;
			&:hover {
				background: $arrow-blue-left no-repeat scroll 50% 50%/13px 24px;
			}
		}
	}
	li.pager-first {
		float: left;
		a {
			text-indent: -9999px;
			overflow: hidden;
			display: block;
			background: $arrow-rose-first no-repeat scroll 50% 50%/26px 24px;
			padding: 0 24px 0 0;
			&:hover {
				background: $arrow-blue-first no-repeat scroll 50% 50%/26px 24px;
			}
		}
	}
	li.pager-item {
		display: inline-block;
		margin: 0 3px;
	}
	li.pager-current {
		background: $directory-pager no-repeat scroll 50% 50% / 30px 30px;
		color:$sunset_orange;
		padding:1.25em 1em;
		//border-radius:50%;
		//border-top: 3px solid;
		//border-bottom: 3px solid;
	}
}
