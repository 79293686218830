.postscript-first .section-row.section-with-promo .section-inner {
	max-width:100% !important;
	padding:0;
}
a.promo {
	background:$middle_yellow $pattern-dark-3;
	display:block;
	margin-top: 190px;
	@include media($narrow) {
		margin-top: 0;
	}
	
	&:hover {
		// background:$white $pattern-dark-6;
	}
	.promo-inner {
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
		.card-image {
			// margin-top: -160px;
			.promo-image {
				margin-top: -160px;
				@include media($narrow) {
					margin-top: 0;
				}
				display: inline-block;
				position: relative;
				z-index: 0;
				img {
					// WILL NOT WORK ON IE OR EDGE
					-webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
					clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
					max-width:320px;
				}
				&:after {
					content: " ";
					background: $promo-diamond no-repeat 100% 100% / 380px 380px;
					display: inline-block;
					height: 380px;
					width: 380px;
					left:-30px;
					top:-30px;
					position: absolute;
					z-index: -1;
				}
			}
			@include media($narrow) {
				float: left;
				margin-top: -60px;
				margin-bottom: 30px;
				margin-left: 50px;
			}
		}
		.card-promo-text {
			padding: 30px 20px;
			@media (max-width:$narrow) {
				text-align: center;
			}
			.promo-title {
				color:$deep_koamaru;
				font:2.5em $title-font-family;
				margin-bottom: 10px;
				@media (max-width:$normal) {
					font-size: 2em;
				}
			}

			.promo-description p {
				color:$primary;
				font:1.625em $base-font-family;
			}

			.promo-follow-text {
				color:$primary;
				font: 700 1.375em $base-font-family;
				
				&:after {
					content:" ";
					background:$arrow-black-right no-repeat 90% 100% / 22px 22px;
					height:25px;
					width:17px;
					display:inline-block;
					margin-left:10px;
					vertical-align: text-top;
				}
			}
		}
		.card-image + .card-promo-text {
			padding: 50px 20px 20px;
			@include media($narrow) {
				padding: 50px;
				margin-top: 90px;
				margin-bottom: 60px;
				margin-left: 370px;
			}
		}
	}
	&:hover .card-promo-text .promo-follow-text {
		color: $sunset_orange;
		&:after {
			background:$arrow-rose-right no-repeat 90% 100% / 22px 22px;
		}
	}
}