.node-type-person {
  
  .node-inner {
    //overflow: hidden;
	  @media (min-width:880px){
	  	margin-left: -20px;
	  }
  }
	.card-bio .label {
		font-size: 2.5em;
   		margin-bottom: 15px;
    	line-height: 1.15em;
	}
  
  .card-suffix {
    display: inline;
  }
  
  .card-profile-details {
    //margin-bottom: $vert-space;
    text-align: center;
	  margin-bottom:$horz-space;
    .card-photo img {
      max-width: 360px;
      width: 100%;
    }
	 @media (min-width:880px) {
		margin-right: -20px;
		float: right;
		max-width: 240px;
		margin-left: $horz-space*2;
	 }
    .card-links {
      background-color: $card-light-bg-color;
      padding: 20px 20px 5px;
      text-align: left;
      display: none;
      @include media($narrow) {
        display: block;
      }
    }
  }

  .card-profile-details-mobile {
    display: block; 
    .card-links {
      background-color: $card-light-bg-color;
      padding: 20px 20px 5px;
      text-align: left;
    }
    @include media($narrow) {
      display: none;
    }
  }
}

.red-button a,
a.red-button {
	background: $sunset_orange;
	color: white;
	display: block;
	font:500 1.125em $title-font-family;
	padding: 10px 20px;
	text-align: center;
	margin-bottom:25px;
	&:hover {
		background: $true_blue;
		color:white;
	}
}


// USER + PERSON SOCIAL ICONS

.node-type-person,
.page-user {
	
	.view-display-id-social {
		.views-row {
			display:inline-block;    
			width: 30px;
			margin: 0px 15px;
		}
	}
   
  .card-links ul li,
  .card-links {
    a /* Unidentified */ {
      background:$ico-web no-repeat 0 50% / 30px 30px;
      color:$action-default;
      display: inline-block;
      line-height: 2;
      margin:0 0 0.75em 0;
      overflow: hidden;
      padding:0 0 0 2em;
      word-wrap:break-word;
		text-indent:-9999px;
     
       &:hover {
         background: $ico-web-over no-repeat 0 50% / 30px 30px;
         color:$action-hover;
       }
    }
  }

  .card-links /* Institutional profile */ {
    .institution a,
    a[href*=".ac.uk"],
    a[href*=".edu"] {
      background: $ico-institution no-repeat 0 50% / 30px 30px transparent;
      
        &:hover {
          background: $ico-institution-over no-repeat 0 50% / 30px 30px transparent;
        }
    }
  }

  .card-links /* Research Gate */ {
    .research-gate a,
    a[href*="researchgate.net"],
    a[href^="https://www.researchgate.net"],
    a[href^="http://www.researchgate.net"],
    a[href^="https://researchgate.net"],
    a[href^="http://researchgate.net"] {
          background: $ico-researchgate no-repeat 0 50% / 30px 30px transparent;
      
        &:hover {
          background: $ico-researchgate-over no-repeat 0 50% / 30px 30px transparent;
        }
    }
  }
  
  .card-links /* Email */ {
    .email a,
    a[href^="mailto:"],
    a[href*="@"] {
      background: $ico-email no-repeat 0 50% / 30px 30px transparent;
      
      &:hover {
        background: $ico-email-over no-repeat 0 50% / 30px 30px transparent;
      }
    }
  }
  
  .card-links /* Google Scholar */ {
    .g-scholar a,
    a[href*="scholar.google"] {
    background: $ico-gscholar no-repeat 0 50% / 30px 30px transparent;
    
      &:hover {
        background: $ico-gscholar-over no-repeat 0 50% / 30px 30px transparent;
      }
    }
  }
  
  .card-links /* linkedin */ {
    .linkedin a,
    a[href*="linkedin.com"],
    a[href^="https://www.linkedin.com"],
    a[href^="http://www.linkedin.com"],
    a[href^="https://linkedin.com"],
    a[href^="http://linkedin.com"] {
      background: $ico-linkedin no-repeat scroll 0 0 / 30px 30px transparent;
      
      &:hover {
        background:$ico-linkedin-over no-repeat scroll 0 0 / 30px 30px transparent;
      }
    }  
  }
  
  .card-links /* twitter */ {
    .twitter a,
    a[href*="twitter.com"],
    a[href^="https://www.twitter.com"],
    a[href^="http://www.twitter.com"],
    a[href^="https://twitter.com"],
    a[href^="http://twitter.com"] {
      background: $ico-twitter no-repeat scroll 0 50% / 30px 30px;
      
      &:hover {
        background: $ico-twitter-over no-repeat scroll 0 50% / 30px 30px;
      }
    }
  }
  
  .card-links /* facebook */ {
    .facebook a,
    a[href*="facebook.com"],
    a[href^="https://www.facebook.com"],
    a[href^="http://www.facebook.com"],
    a[href^="https://facebook.com"],
    a[href^="http://facebook.com"] {
      background: $ico-facebook no-repeat scroll 0 50% / auto 30px ;
    
      &:hover {
        background: $ico-facebook-over no-repeat scroll 0 50% / auto 30px;
      }
    }
  }
  
  .card-links /* google+ */ {
    .g-plus a,
    a[href*="plus.google.com"],
    a[href^="https://www.plus.google.com"],
    a[href^="http://www.plus.google.com"],
    a[href^="https://plus.google.com"],
    a[href^="http://plus.google.com"] {
      background:$ico-gplus no-repeat scroll 0 50% / 30px 30px transparent;
      
      &:hover {
        background: $ico-gplus-over no-repeat scroll 0 50% / 30px 30px transparent;
      }
    }
  }
  
  .card-links /* orcid */ {
    .orcid a,
    a[href*="orcid.com"],
    a[href^="https://www.orcid.com"],
    a[href^="http://www.orcid.com"],
    a[href^="https://orcid.com"],
    a[href^="http://orcid.com"] {
      background:$ico-orcid no-repeat scroll 0 0 / 30px 30px transparent;
      
      &:hover {
        background: $ico-orcid-over no-repeat scroll 0 0 / 30px 30px transparent;
      }
    }
  }
  
  .card-links /* YouTube */ {
    .youtube a,
    a[href*="youtube.com"],
    a[href^="https://www.youtube.com"],
    a[href^="http://www.youtube.com"],
    a[href^="https://youtube.com"],
    a[href^="http://youtube.com"] {
      background:$ico-youtube no-repeat scroll 0 50% / 30px auto transparent;
      
      &:hover {
        background: $ico-youtube-over no-repeat scroll 0 50% / 30px auto transparent;
      }
    }
  }
  
  .card-links /* flickr */ {
    .youtube a,
    a[href*="flickr.com"],
    a[href^="https://www.flickr.com"],
    a[href^="http://www.flickr.com"],
    a[href^="https://flickr.com"],
    a[href^="http://flickr.com"] {
      background:$ico-flickr no-repeat scroll 0 50% / 30px auto transparent;
      
      &:hover {
        background: $ico-flickr-over no-repeat scroll 0 50% / 30px auto transparent;
      }
    }
  }
}