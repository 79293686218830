.view-adverts {
  &.content-ad {
    margin: 20px calc(50% - 364px);
  }
  &.sidebar-ad {
    margin-bottom:20px;
  }
  .view-header {
    font-size:0.888em;
  }
}
@media (max-width:727px) {
	.ad-leaderboard {display: none; }
}