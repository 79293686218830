.view.view-mode-grid > .view-content, .view.view-mode-map > .item-list, .view.view-mode-map > .attachment { display:block; }
.view.view-mode-map > .view-content, .view.view-mode-map > .item-list, .view.view-mode-grid > .attachment { display:none; }

.view-display-id-all,
.view-display-id-past,
 {
  .view-header,
  .view-content {
    padding: 0 10px;
  }
  .card-feed-link {
    padding-right: 10px;
  }
}

.view-id-news_events {
  .view-header {
    color: $deep_koamaru;
    text-align: center;
  }
}
.view-directory-tags {
  float: left;
  width: auto;
}

// Diamond list
.diamond-list,
.diamond-table {
  padding: 0 30px;
  position: relative;
	.diamond-header.views-content {
		padding: 30px 10px 30px 40px;
	}
  .diamond-header,
	//caption,
	h3	{
    margin-bottom: 0;
    font-size: 1.75rem;
    line-height: 30px;
    background: $white;
    padding: 20px 30px;
    border-left: 3px solid $middle_yellow;
    clear: both;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
    position: relative;
    &:before {
      content: "";
      display: block;
      height: 37px;
      width: 37px;
      background: $diamond-yel no-repeat scroll 50% 50%;
      position: absolute;
      left: -21px;
    }
  }
  .views-row {
    width: 100%;
    float: left;
    background: $ghost_white;
    border-top: 1px solid $pale_grey_two;
    font-size: 1.250rem;
    line-height: 28px;
    &.views-row-last {
      margin-bottom: 2em;
    }
    .card-title a {
      padding: 14px 20px;
      display: block;
    }
  }
  &.diamond-list-3 {
    .views-row {
      @include media($narrow) {
        width: 33.333%;
        &:nth-child(3n+2) {
          border-left: 1px solid $pale_grey_two;
          border-right: 1px solid $pale_grey_two;
        }
      }
    }
  }
  &.diamond-list-2 {
    .views-row {
      @include media($narrow) {
        width: 50%;
        &.views-row-odd {
          border-right: 1px solid $pale_grey_two;
        }
      }
    }
  }
}

//.view-id-health_conditions_glossary {
//	//&.diamond-list,
//	.diamond-list {
//		padding:0  15px;
//		margin-top:30px;
//		.views-row {
//			border-right: 1px solid $pale_grey_two;
//		}
//	}
//
//}
.view-health-conditions-glossary.diamond-list {
	margin-top: 30px;
	//padding:0 20px;
}

// Diamond box
.diamond-box {
  .views-row {
    background: white $pattern-dark-6;
    padding: 30px;
    border-left: 3px solid $middle_yellow;
    box-shadow: $box-shadow;
    position: relative;
		margin-bottom:20px;
    h3 {
      a {
        //display: flex;
        word-break: break-word;
				font-size: 1.625rem;
        &:after {
          content: "";
          display: inline-block;
          margin-left: 20px;
          height: 25px;
          width: 25px;
          background: $arrow-blue-right no-repeat scroll 50% 50% / auto 25px;
          vertical-align: text-bottom;
        }
        &:hover:after {
          background: $arrow-red-right no-repeat scroll 50% 50% / auto 25px;
        }
      }
    }
    &:before {
      content: "";
      display: block;
      height: 28px;
      width: 28px;
      background: $diamond-yel no-repeat scroll 50% 50%;
      position: absolute;
      left: -16px;
    }
  }
}

.page-health-conditions {
  ul.tabs.primary {
      display: none;
  }
}