// /* Add all of your colours to var-colors and apply them to different remora elements in var-config.
// Unless a style needs to be backported to remora, you do not need to create anything in var-config.
// Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary           : #555555; /* davys_grey */
$secondary         : #686868; /* sunset_orange */



// /* MAIN PALETTE */ //
$dogwood_rose      : #D81E5B; /* dogwood_rose */ // TO REPLACE AND DELETE
$light_blue        : #84D0F5; /* lightblue */ // TO REPLACE AND DELETE
$faded_blue        : #5D86C5; /* faded_blue */ // TO REPLACE AND DELETE
$middle_red_purple : #38023B; /* middle_red_purple */ // TO REPLACE AND DELETE


$davys_grey				: #555555; /* davys_grey */
$sapphire_blue		: #0557A5; /* sapphire_blue */
$true_blue				: #0776DF; /* true_blue */
$sunset_orange		: #FE5F55; /* sunset_orange */


// /* SEC PALETTE */ //
$pale_grey         : #f5f8fa;  /* pale_grey */  // TO REPLACE AND DELETE
$pale_grey_two     : #e4e8eb;  /* pale_grey_two  */
$battleship_grey   : #6b7280; /* battleship_grey */ // TO REPLACE AND DELETE
$japanese_indigo   : #6b7280; /* japanese_indigo */ // TO REPLACE AND DELETE
$platinum          : #e0e0e2; /* platinum */ // TO REPLACE AND DELETE
$pearl_aqua        : #81D2C7; /* pearl_aqua */ // TO REPLACE AND DELETE
$dark_lavender     : #6250A1; /*  dark_lavender  */ // TO REPLACE AND DELETE
$lavender_mist     : #E6E6FA; /*  lavender_mist  */ // TO REPLACE AND DELETE
$electric_blue		: #008CE6;  /* electric_blue  */

$deep_koamaru			: #29335C; /* deep_koamaru */
$middle_yellow    : #FFEB00; /* middle_yellow */
$yellow_pantone		: #F5E100; /* yellow_pantone */
$quick_silver			: #A1A1A1; /* quick_silver */
$gainsboro				: #D9D9EA; /* gainsboro */
$anti_flash_white	: #EFEFF6; /* anti_flash_white */
$ghost_white			: #F8F8FB; /* ghost_white */
$wild_blue_yonder	: #ADADD2; /* wild_blue_yonder */
$pastel_grey			: #CCCCCC; /* pastel_grey */
$gray_blue				: #8D8DBF; /* gray_blue */
$granite_grey			: #686868; /* granite_grey */
$light_grey			  : #D0D0D6; /* light_grey */
$charleston_green	: #2B2B2B; /* charleston_green */
$nickel           : #727272; /* nickel */
$green            : #5AB728; /* green */

// MEMBER LEVELS
$gold				:#FFD222; /* gold */
$silver				:#95A5BD; /* silver */
$bronze				:#E89F75; /* bronze */

$black             : #000;
$grey              : #666;
$white             : #FFF;


// MENU
$middle_red					: #E28871;
$pastel_pink				: #E2B1A5;
$dust_storm					: #E2C7C0;
$timberwolf					: #E2D8D5;


// FILES
$dark_spring_green 		: #207347; /* xlsx */
$bdazzled_blue 				: #2D5994; /* docx */
$lemon_curry 					: #D19B15; /* jpg */
$internationl_orange 	: #C11E07; /* pdf */
$dark_pastel_red 			: #D04727; /* pptx */
$dark_imperial_blue 	: #023A6D; /* txt */
//$black 							: #000000; /* zip */

$xlsx_color	 					: $dark_spring_green; /* xlsx */
$docx_color	 					: $bdazzled_blue; /* docx */
$jpg_color	 					: $lemon_curry; /* jpg */
$pdf_color	 					: $internationl_orange; /* pdf */
$pptx_color	 					: $dark_pastel_red; /* pptx */
$txt_color	 					: $dark_imperial_blue; /* txt */
$zip_color	 					: black; /* zip */


// /* LINKS */ //
$action-default      : $sapphire_blue;
$action-hover        : $sunset_orange;
$action-active       : $sunset_orange;
$action-active-hover : $sunset_orange;

$action-alt-default      : $sunset_orange;
$action-alt-hover        : $true_blue;
$action-alt-active       : $true_blue;
$action-alt-active-hover : $true_blue;


// /* PATTERNS */ //
//$pattern-light-3 		: $bg-pattern-light-3 repeat 50% 50% / 85px 40px;
//$pattern-light-6 		: $bg-pattern-light-6 repeat 50% 50% / 85px 40px;
//$pattern-dark-3 		: $bg-pattern-dark-3 repeat 50% 50% / 85px 40px;
//$pattern-dark-6 		: $bg-pattern-dark-6 repeat 50% 50% / 85px 40px;

// SAFARI PATTERNS
$saf-pattern-dark-6 		: url("../images/bg-pattern-dark-6.png") repeat 50% 50% / 85px auto;

// /* SHADOWS */ //
// $box-shadow-rgba    : rgba(0,0,0,0.2);
// $box-shadow         : 0 2px 4px 0 $box-shadow-rgba;
// $box-side-shadow    : 0 1px 4px 0 $box-shadow-rgba;
$box-shadow-rgba		: rgba(0,0,0,0.1);
$box-shadow 				: 0 0 4px 0 $box-shadow-rgba;
$box-side-shadow		: 0 0 4px 0 $box-shadow-rgba;

// /* BORDERS */ //
$primary-border-color         	: $gainsboro; //rgba(44, 63, 78,0.1);
$primary-border-radius        	: 0;
$primary-border               	: 1px solid $primary-border-color;

$secondary-border-color       	: $quick_silver; //$rgba(44, 63, 78,0.1);
$secondary-border-color-radius	: 0;
$secondary-border             	: 1px solid $secondary-border-color;

$tertiary-border-color       		: $true_blue;
$tertiary-border-radius       	: 0;
$tertiary-border         				: 1px solid $tertiary-border-color;

$quaternary-border-color				: $sunset_orange;
$quaternary-border-radius       : 0;
$quaternary-border              : 1px solid $quaternary-border-color;

$quinary-border-color        		: $true_blue;
$quinary-border-color-radius 		: 0;
$quinary-border              		: 3px solid $quinary-border-color;

$senary-border-color         		: $sunset_orange;
$senary-border-radius        		: 0;
$senary-border               		: 3px solid $senary-border-color;

$septenary-border-color         : $light_grey;
$septenary-border-radius        : 0;
$septenary-border               : 3px solid $septenary-border-color;

$octonary-border-color         : $gray_blue;
$octonary-border-radius        : 0;
$octonary-border               : 1px solid $octonary-border-color;

$base-border                   	: 1px solid $primary-border-color; //rgba(44, 63, 78,0.1);
$base-border-radius            	: 2px;

$gold-border-radius        : 0;
$gold-border               : 3px solid $gold;

$silver-border-radius        : 0;
$silver-border               : 3px solid $silver;

$bronze-border-radius        : 0;
$bronze-border               : 3px solid $bronze;

// /* TABLES */ //
$table-bg-color           : $ghost_white;
$base-accent-color        : #477DCA;
$table-border-color       : $pale_grey_two;
$table-cell-border        : 1px solid $pale_grey_two;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : white;
$table-header-border      : 1px solid $primary-border-color; //darken($primary-border-color, 10%);
$table-hover-bg-color     : lighten($middle_yellow, 43%); //lighten($platinum, 10%);
$table-stripe-bg          : darken($table-bg-color, 2%);
$table-stripe-bg-hover    : lighten($middle_yellow, 5%);
$table-padding            : 0.625rem 1.25rem;
$table-caption-bg-color   : white $pattern-dark-6;
$table-caption-text-color : $deep_koamaru;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.75em/1.875 $title-font-family;

// /* FORMS  */ //
$fieldset-border-color : $wild_blue_yonder;
$form-box-shadow       : none; //$box-shadow;
$form-box-shadow-focus : none; //$box-shadow;
$form-border-color     : $primary-border-color;
$form-border           : 1px solid $primary-border-color;
$form-border-radius    : 0;
$form-bg-color			   : $ghost_white;
$form-bg-atl-color		: white;


$button-bg             : $sunset_orange;
$button-hover-bg       : $sapphire_blue;
$button-text           : white;
$button-border         : none;
$button-border-radius  : 2px;
$button-hover-text     : white;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white;
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : $pale_grey_two !default;
