.site-title a { background:$logo-en no-repeat scroll 0 0 transparent; }

#site-header {
	background-color:$header-bg-color;
	//@media (max-width:720px) and (max-width:879px) {
	@media (max-width:919px) and (max-width:$nav-desktop-trigger-width - 1px) {
		border-bottom: 2px solid $nav-active-bg-color;
	}
	@media (max-width:919px) {
		border-bottom: 3px solid $nav-active-bg-color;
	}
	&:after {
		content: " ";
		display: block;
		height: 0;
		clear: both;
	}
	//
	// /* Change .header-outer-wrapper to 
	// .header-inner-wrapper, main-nav-wrapper
	// to make the menu a band in it's own right. */
	//
	.user-menu {
		float: right;
    	margin-top: -40px;
		margin-right:20px;
		@media (max-width:919px) {
			position:absolute;
			right:0;
		}
		ul {
			list-style-type:none;
			li {
				display:inline-block;
				margin-left:20px;
				a {
					color:$primary;
					font-weight:700;
					&:hover {
						color:$sunset_orange;
					}
					.active {
						color:$true_blue;
					}
				}
				a.active {
					color:$true_blue;
				}
			}
		}
    .menu-mlid-3783 {
      display:none;
    }
	}
	.block-menu.header-links {
		clear: right;
    	margin-top: 0;
    	margin-bottom: 20px;
		@media (max-width:919px) {
			display:none;
		}
		//@media (max-width:879px) {
		@media (max-width:$nav-desktop-trigger-width - 1) {
			position:absolute;
			top:-35px;
		}
		a {
			border: solid 1px $gainsboro;
			color:$true_blue;
			font-size:1.125em;
			font-weight:700;
			padding:8px 20px;
			&:hover {
				color:$sunset_orange;
				border: solid 1px $sunset_orange;
			}
			&.active {
				color:white;
				background:$true_blue;
				&:hover {
					border: solid 1px white;
				}
			}
		}
		li.active-trail {
			a {
				color:white;
				background:$true_blue;
				&:hover {
					border: solid 1px white;
				}
			}
			ul { display: none; }
		}
	}
	/*.header-links {
		clear: right;
    	margin-top: 0;
    	margin-bottom: 20px;
		@media (max-width:719px) {
			display:none;
		}
		@media (max-width:879px) {
			position:absolute;
			top:-35px;
		}
		a { 
			&.active {
				color:white;
				background:$true_blue;
			}
		}
	}*/
	.header-inner-wrapper,
	#main-navigation {
		position:relative;
		@include outer-container($site-max-width);
	}
	.site-branding {
		margin-top:2.5rem;
		margin-left:1.25rem;
		position: relative;
    //@include media(880px) {
    @include media($nav-desktop-trigger-width) {
			top:0;
		}
		.site-title {
			//position:absolute;
			//left:0;
			//top:0;//2.5rem;
			float: left;
			margin-bottom: 2.5rem;
			
			a {
				background-size:contain;
				display:block;
				text-indent:-999em;
				overflow:hidden;
				width:266px;
				height:63px;
				@media (max-width:360px) {
					width:170px;
					margin-top:20px;
					margin-bottom:-45px;
					margin-left:-10px;
				}
				@media (min-width:361px) and (max-width:919px) {
					width:180px;
					margin-top:20px;
					margin-bottom:-25px;
					height:45px;
				}
				//@media (min-width:720px) and (max-width:879px) {
				@media (min-width:$narrow) and (max-width:$nav-desktop-trigger-width - 1) {
					margin-top:40px;
					margin-bottom:-20px;
				}
			}
		}
		.site-slogan {
			position:absolute;
			left:235px;
			top:2.5rem;
		}
	}
//	/*.block-search {
//		float:right;
//		height:10rem;
//		@include media(880px) {
//			height:auto;
//		}
//		h2 {
//			font-family:$base-font-family;
//			font-size:1rem;
//			color:$primary;
//			float:left;
//			line-height:1.5;
//			margin-top:1rem;
//			margin-right:0.75rem;
//			cursor:pointer;
//			display:none;
//			@include media(880px) {
//				margin-right:2.5rem;
//			}
//		}
//		.form-wrapper {
//			display:none;
//			@include media(880px) {
//				display:block;
//			}
//		}
//		.form-item-search-block-form {
//			margin-top:0.75rem;
//			margin-right:0.75rem;
//			margin-bottom:0;
//			@include media(880px) {
//				margin-bottom:3rem;
//			}
//			@include media($wide) {
//				margin-bottom:3rem;
//			}
//			float:left;
//			input.form-text {
//				width:10rem;
//				height:1.875rem;
//				background-color:$header-search-field-bg-color;
//				color:$header-search-field-text-color;
//				box-shadow:$header-search-field-box-shadow;
//				border:$header-search-field-border;
//				border-radius:$header-search-field-border-radius;
//				font:$header-search-field-font;
//				padding:0.375rem 0.75rem;
//			}
//		}
//		.form-actions {
//			float:left;
//			margin-top:0.75rem;
//			@include media(880px) {
//				margin-bottom:5rem;
//			}
//			input.form-submit {
//				background: transparent $search no-repeat scroll 0 0;
//				background-size:14px;
//				width:0.875rem;
//				height:0.875rem;
//				text-indent:-999em;
//				overflow:hidden;
//				padding:0;
//				position:absolute;
//				right:1.25rem;
//				top:1.25rem;
//			}
//		}
//	}*/
	
	.search-toggle-wrapper {
    position: absolute;
    top: -60px;
		right: 58px;
		//display:none;
		
		@include media($narrow) {
			top: -90px;
    	right: 80px;
		}

    //@include media(880px) {
    @include media($nav-desktop-trigger-width) {
			top: 0;
    	right: 0;
		}

		//@include media(880px) {
			display: block;
			
			.search-toggle {
				height: 90px;
				width: 70px;
				text-indent: -9999px;
				background: transparent $search-icon-white no-repeat scroll 50% 50%;
				background-size: 35px !important;

				//@media (min-width:$narrow) and (max-width:$normal) {
				//	height: 85px;
				//	top: -39px; 
				//}
				@include media($mobile) {
					background-size: 30px !important;
					background: transparent $search-icon no-repeat scroll 50% 50%;
					height: 60px;
    				width: 60px;
					//margin-top:20px;
					//margin-right:-8px;
				}
				
				@include media($narrow) {
					background-size: 44px !important;
					background: transparent $search-icon no-repeat scroll 50% 50%;
					height: 80px;
    				width: 80px;
					margin-top:10px;
				}
				
    //@include media(880px) {
    @include media($nav-desktop-trigger-width) {
					background-size: 22px !important;
					background: transparent $search-icon-white no-repeat scroll 50% 50%;
					height: 35px;
					width: 60px;
					margin-top:0;
				}

				@media (min-width:1151px) {
					height: 43px;
				}
				&:hover {
					background: $nav-hover-bg-color $search-icon-white no-repeat scroll 50% 50%;
					cursor: pointer;
				}

				&.active {
					background-size: 28px !important;
					background: $nav-highlight-bg-color $close-icon-white no-repeat scroll 50% 50%;
					@media (min-width:361px) and (max-width:889px) {
						background-size: 34px !important;
					}

					&:hover {
						background-size: 28px !important;
						background: $nav-hover-bg-color $close-icon-white no-repeat scroll 50% 50%;
						@media (min-width:361px) and (max-width:889px) {
							background-size: 34px !important;
						}
					}
				}
			}
		//}
	}
	
	.block-search {
		display:none;
		
		
		//@include media(880px) {
			display: block;
			right: 0;
		//}
		
    //@include media(880px) {
    @include media($nav-desktop-trigger-width) {
			position: absolute;
		}
		
		#search-block-form {
			display:none;
			
			//@include media(880px) {
				position: absolute;
				right: 0;
				//top: 35px;
				background: $header-search-bg-color;
				z-index: 1;
				padding: 10px;
			  max-width: 100%;
			box-shadow: $box-shadow;
			@include media($nav-desktop-trigger-width) {
				top: 35px;
			}
			@include media(1160px) {
				top: 40px;
			}
			@include media(1270px) {
				top: 43px;
			}
				
    //@include media(880px) {
    @include media($nav-desktop-trigger-width) {
				//top: 45px;				
		    max-width: none;
			}
			//}

			h2 {
				display:none;
			}
			.form-item-search-block-form {
				margin: 0;
				
				//@include media(880px) {
				@include media($nav-desktop-trigger-width) {
					float:left;
				}
				
				input.form-text {
					width:26rem;
					height:2.5rem;
					background-color:$header-search-field-bg-color;
					color:$header-search-field-text-color;
					box-shadow:$header-search-field-box-shadow;
					border:$header-search-field-border;
					border-radius:$header-search-field-border-radius;
					font:$header-search-field-font; 
					padding:0.375rem 0.75rem;
					margin: 0;
					font-size: 1.125rem;
										
   				max-width: 100%;
				}
			}
			.form-actions {
				float:left;
				margin:0;
				input.form-submit {
					background: transparent $search-icon no-repeat scroll 0 0;
					background-size:24px;
					width:24px;
					height:24px;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:1.25rem;
					top:1.2rem;
					
					&:hover {
						background: transparent $search-icon-hover no-repeat scroll 0 0;
						cursor: pointer;
						background-size:24px;
					width:24px;
					height:24px;
					text-indent:-999em;
					overflow:hidden;
					padding:0;
					position:absolute;
					right:1.25rem;
					top:1.2rem;
					}
				}
			}
		}
	}
	.block-menu {
		float:right;
		//margin-top:1rem;
		margin-right:0.5rem;
		z-index: 5;
		
    //@include media(880px) {
    @include media($nav-desktop-trigger-width) {
			//margin-right:1.5rem;
			//margin-bottom:5rem;
		}
		li {
			display:inline-block;
			margin:0 0.75rem 0 0;
		}
		
		a {
			font:$header-menu-font;
			color:$header-menu-link-color;
			
			&:hover {
				color:$header-menu-link-hover;
			}
		}
	}
	.block-locale {
		clear:both;
		display:none;
	}
}

.logged-in #site-header .user-menu ul {
	li.menu-mlid-3310,
	li.menu-mlid-3311 {
		display:none;
	}
}

#main-navigation {
	@media (min-width:920px) {
		li.menu-mlid-3658,
		li.menu-mlid-3317,,
		li.menu-mlid-3322 {
			display:none;
		}
	}
}
