.node-type-travel {
  .view-travel-requirements {
    margin-bottom: 2em;
  }
  section#content {
    padding: 0;
    .card-description {
      background: $white;
      padding: 20px;
    }
  }
  .side-row.view-travel-requirements {
    padding: 0;
    h2 {
      margin: 0;
    }
    img {
      display: block;
    }
  }
}
