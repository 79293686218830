@mixin slick-controls-default() {
	.slick-controls, .slick-nav {
		button.slick-arrow {
			&.slick-prev {
				background: $slick-default-prev-bg;
				&:hover {
					background: $slick-default-prev-hover-bg;
				}
			}
			&.slick-next {
				background:$slick-default-next-bg;
				&:hover {
					background:$slick-default-next-hover-bg;
				}
			}
		}
		.slick-dots {
			li {
				button {
					border:$slick-default-dot-hover-border;
					&:hover {
						border:$slick-default-dot-hover-border;
						background:$slick-default-dot-active-hover-bg-color;
					}
				}
				&.slick-active button {
					border:$slick-default-dot-border !important;
					background-color:$slick-default-dot-active-bg-color;
					&:hover {
						background-color:$slick-default-dot-active-hover-bg-color;
						border:$slick-default-dot-hover-border !important;
					}
				}
			}
		}
	}
	
	.slick-controls { background: $slick-default-controls-bg; }
	.slick-nav { background: $slick-default-nav-bg; }
}

@mixin slick-controls-alt-1() {
	.slick-controls, .slick-nav {
		button.slick-arrow {
			&.slick-prev {
				background:$slick-alt-1-prev-bg;
				&:hover {
					background:$slick-alt-1-prev-hover-bg;
				}
			}
			&.slick-next {
				background:$slick-alt-1-next-bg;
				&:hover {
					background:$slick-alt-1-next-hover-bg;
				}
			}
		}
		.slick-dots {
			li {
				button {
					border:$slick-alt-1-dot-default-border;
					&:hover {
						border:$slick-alt-1-dot-hover-border;
					}
				}
				&.slick-active button {
					background-color:$slick-alt-1-dot-active-bg-color;
					&:hover {
						background-color:$slick-alt-1-dot-active-hover-bg-color;						
					}
				}
			}
		}
	}
	.slick-controls { background: $slick-alt-1-controls-bg; }
	.slick-nav { background: $slick-alt-1-nav-bg; }
}


@mixin slick-controls-alt-2() {
	.slick-controls, .slick-nav {
		button.slick-arrow {
			&.slick-prev {
				background:$slick-alt-2-prev-bg;
				&:hover {
					background:$slick-alt-2-prev-hover-bg;
				}
			}
			&.slick-next {
				background:$slick-alt-2-next-bg;
				&:hover {
					background:$slick-alt-2-next-hover-bg;
				}
			}
		}
		.slick-dots {
			li {
				button {
					border:$slick-alt-2-dot-default-border;
					&:hover {
						border:$slick-alt-2-dot-hover-border;
					}
				}
				&.slick-active button {
					background-color:$slick-alt-2-dot-active-bg-color;
					&:hover {
						background-color:$slick-alt-2-dot-active-hover-bg-color;						
					}
				}
			}
		}
	}
	.slick-controls { background: $slick-alt-2-controls-bg; }
	.slick-nav { background: $slick-alt-2-nav-bg; }
}
