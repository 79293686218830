fieldset {
	border: $secondary-border;
	margin: 0 0 $vert-space/2;
	padding: $vert-space $horz-space;
}

input,
label,
select {
	display: block;
	font-family: $base-font-family;
	font-size: $base-font-size;
	-moz-appearance:none;
}

label {
	font-weight: 600;
	margin-bottom: $vert-space/4;

	&.required::after {
		content: "*";
	}

	abbr {
		display: none;
	}
}
.marker,
.form-required,
.crm-container .crm-marker {
	color: $sunset_orange;
}

#{$all-text-inputs},
select[multiple=multiple],
textarea {
	background-color: white;
	border: $form-border;
	border-radius: $form-border-radius;
	box-shadow: $form-box-shadow;
	box-sizing: border-box;
	font-family: $base-font-family;
	font-size: 1.125rem;
	margin-bottom: 1.125em;
	padding: $vert-space/3 $horz-space/2;
	transition: border-color;
	width: 100%;
	height: 44px;

	&:hover {
		border-color: darken($form-border-color, 2%);
	}

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}
#admin-menu input[type="text"] {
	height: auto;
}
textarea {
	resize: vertical;
}

input[type="search"] {
	@include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
	display: inline;
	margin-right: $vert-space/4;
}

input[type="file"] {
	padding-bottom: $vert-space/2;
	width: 100%;
}

select {
	width: auto;
	font-family: $base-font-family;
	margin-bottom: $vert-space/2;
	max-width: 100%;
	transition: border-color;

	-webkit-appearance: none;
	-webkit-border-radius: 0px;
	min-width: 200px;
	box-sizing: border-box;
	min-height: 42px;
	padding: 8px 50px 8px 15px;
	min-width:200px;
	box-shadow: none;
	border: $octonary-border;
	font-size: 1rem;
	border: $primary-border;
	border-radius: $base-border-radius;
	background: $form-bg-atl-color $arrow-grey-border-down no-repeat scroll 100% 50% / 42px 42px;

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}

.views-exposed-form label.option { font-weight: 500; }

.form-checkboxes,
.form-type-checkbox {
	input[type=checkbox] {
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height:1px;
		width:1px;margin:-1px;
		padding:0;
		border:0;
	}
	input[type=checkbox] + label.option {
		display:inline-block;
		line-height:1rem;
		background-repeat:no-repeat;
		background-position: 0 1px;
		font-size:1rem;
		vertical-align:middle;
		cursor:pointer;
		padding: 0 0 0 23px;
	}
	input[type=checkbox]:checked + label.option {
		background: $checkbox-active no-repeat scroll 0 1px / 13px 13px transparent;
		font-weight: 600;
	}
	label.option {
		background: $checkbox-default no-repeat scroll 0 0 / 13px 13px transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:hover {
			background: $checkbox-hover no-repeat scroll 0 0 / 13px 13px transparent;
			color:$action-alt-hover;
			font-weight: 600;
		}
	}

	.description { margin-left: 1.75em; }
}

.form-type-radio {
	input[type=radio] {
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rect(0 0 0 0);
		height:1px;
		width:1px;margin:-1px;
		padding:0;
		border:0;
	}
	input[type=radio] + label.option {
		padding-left:23px;
		display:inline-block;
		line-height:1rem;
		background-repeat:no-repeat;
		background-position: 0 1px;
		font-size:1rem;
		vertical-align:middle;
		cursor:pointer;
	}
	input[type=radio]:checked + label.option {
		background: $radio-active no-repeat scroll 0 1px / 13px 13px transparent;
		font-weight: 600;
	}
	label.option {
		background: $radio-default no-repeat scroll 0 0 / 13px 13px transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		&:hover {
			background: $radio-hover no-repeat scroll 0 0 / 13px 13px transparent;
			color:$action-alt-hover;
			font-weight: 600;
		}
	}
}


// /*  EDIT NODES FORMS */ //
.page-node-edit,
.page-user-edit {
	.form-item,
	.form-disabled,
	.field-label,
	.form-item .form-item.form-type-password,
	.form-item.form-type-date-popup .form-item {
		label {
			color: $deep_koamaru;
			font: 500 1.125rem/1 $title-font-family;
			padding: 10px 0 10px;
		}

		.form-item,
		.form-disabled {
			& > label {
				color: $primary;
				font: 500 1rem/1 $base-font-family;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
	table {
		width: 100%;
		display: block;
		thead,
		tbody {
			width: 100%;

		}
		th { background-color: $anti_flash_white; }
		tbody tr {
			&.odd {
				background-color: white;
				&:hover { background-color: $table-hover-bg-color; }
			}
		td {
			//border-left: 1px solid $primary-border-color;
			//border-right: 1px solid $primary-border-color;
			//background-color: $table-header-bg-color;

			&.field-multiple-drag {
				border-right: none;
			}
			input { margin-bottom: 0; }
		}
	}
}

	input[type=submit] {
		margin-right: 1em;

		&.field-add-more-submit {
			margin-top: 1.5em;
		}
	}
}

/* Node edit/add styling */
.page-node-edit,
.page-node-add,
.page-civicrm {

	#page-title { margin: 0 15px 30px; }

	.node-service-form,
	.node-opportunity-form,
	.node-academic_programme-form,
	.node-research_program-form,
	.node-product-form {
		.field-name-field-organisation-er { display: none; }
	}
	.node-opportunity-form {
		.field-name-field-date-posted { display: none; }
	}
	.node-organisation-form {
		.form-item-field-organisation-hide-und,
		.form-item-language{ display: none; }
	}
	a.tabledrag-handle .handle {
	    height: 23px;
	    width: 20px;
	}
	.field-multiple-table {
		.form-type-textfield {
			float: left;
			margin-right: 1rem;
			//background: none;
		}
		.multiple-fields-remove-button {
			margin-top: 10px;
		}
		thead th.field-label {
			padding-left: 1.25rem;
		}
	}
	input#edit-submit {
		margin-right: 1em;
	}

	iframe.cke_wysiwyg_frame.cke_reset {
		box-sizing: border-box;
		padding: 10px;
	}
	.cke {
	.cke_contents {
			height: 120px !important;
		}
	}

	.form-radios,
	.form-checkboxes {
		padding: 5px 20px;
		background: white;
		.form-item.form-type-radio,
		.form-item.form-type-checkbox {
			display: inline-block;
			width: 20%;
			min-width: 200px;
		}
	}
	.form-item label {
		font-family: $title-font-family;
		font-size: 1.250rem;
		font-weight: 300;
	}
}
input + .description { margin: -0.5625rem 5px 1.5rem; }
fieldset:not(#edit-secondary) {
	margin-bottom: 40px;
	padding: 20px;
	background: $ghost_white;
	position: relative;
	border: none;

	//margin-top: 1em;
	//padding: 0px 20px 20px;
	//border: 1px solid $primary-border-color;

	legend {
		margin: 0 0px -20px -20px;
		width: calc(100% + 40px);
		font: $side-row-title-font;
		background:$table-caption-bg-color;
		border-bottom:$table-caption-border;
		color:$table-caption-text-color;
		padding:$vert-space/4 $horz-space*1.5;
		border-left: 3px solid $middle_yellow;
		position: static;

		@include media($narrow) {
			font-size: 1.75rem;
			line-height: 1.875;
		}

		&:before {
			content: "";
			display: block;
			height: 37px;
			width: 37px;
			background: $diamond-yel no-repeat scroll 50% 50% / 25px 25px;
			position: absolute;
			left: -17px;
			margin-top:10px;
			@include media($narrow) {
				background-size: 36px 37px;
			}
		}
	}
	.form-item:last-child { margin-bottom: 0; }

	fieldset {
		margin: 20px 0;
		padding: 0;
		legend {
			font-size: 1.25em;
			background:none;
			border:none;
			color:$table-caption-text-color;
			padding:0;
			margin: 0 0 -10px;;
			&:before {
				content: "";
				display: none;
			}
		}
		.form-item {
			padding: 0;
		}
	}
	.form-item {
		padding: 10px  0;
		.description {  	color: $nickel; }

	}
	.image-widget-data .form-item { padding: 0;}
	#{$all-text-inputs} {
		max-width: 720px;
	}
}
fieldset.date-combo .container-inline-date .date-padding { padding: 10px 0; }


// / CIVI */
.crm-container {
	.crm-section {
		.label {
			float: none;
			width:auto;
			text-align: left;
		}
		.content { margin-left: 0; }
	}
	.crm-button,
	a.button,
	a.button:link,
	a.button:visited,
	input.crm-form-submit,
	input[type=button],
	.ui-dialog-buttonset .ui-button,
	.crm-button input.crm-form-submit {
		text-shadow: none;
    background: $button-bg;
    color: $button-text;
  	font: $button-font;
    margin: 0 6px 0 0;
    padding: 2px 6px;
    text-decoration: none;
    cursor: pointer;
    border: $button-border;
    border-radius: $button-border-radius;
		display: inline-block;
		padding: 0.75rem 1rem;
		text-decoration: none;
		user-select: none;
		vertical-align: middle;
		white-space: nowrap;

		&:hover,
		&:focus {
			background-color: $button-hover-bg;
			color: $button-hover-text;
		}
		&:disabled {
			cursor: not-allowed;
			opacity: 0.5;
		}
	}
	#civicrm-footer { display: none; }
}
#crm-container.crm-public {
	input[type="text"],
	input[type="password"],
	input[type="email"],
	select {
		font-family: $base-font-family;
		font-size: 1.125rem;
		padding: $vert-space/3 $horz-space/2;
		border: $form-border;
		border-radius: $form-border-radius;
		vertical-align: middle;
		max-width: 720px;
		width: 100%;
		-moz-appearance:none;
		box-shadow: $form-box-shadow;
		box-sizing: border-box;
		margin-bottom: 1.125em;
		transition: border-color;
		height: 44px;
		background: white;

		&:hover {
			border-color: darken($form-border-color, 2%);
		}

		&:focus {
			border-color: $action-default;
			box-shadow: $form-box-shadow-focus;
			outline: none;
		}
	}
}
#crm-container.crm-public select {
	font-family: $base-font-family;
	margin-bottom: $vert-space/2;
	max-width: 100%;
	transition: border-color;
	-webkit-appearance: none;
	-webkit-border-radius: 0px;
	min-width: 200px;
	box-sizing: border-box;
	min-height: 42px;
	padding: 8px 50px 8px 15px;
	min-width:200px;
	box-shadow: none;
	border: $octonary-border;
	font-size: 1rem;
	border: $primary-border;
	border-radius: $base-border-radius;
	background: $form-bg-atl-color $arrow-grey-border-down no-repeat scroll 100% 50% / 42px 42px;

	&:focus {
		border-color: $action-default;
		box-shadow: $form-box-shadow-focus;
		outline: none;
	}
}



html.js fieldset {
	&.collapsible .fieldset-legend {
		background:none;
		padding: 0 40px 0 0;
		background: $ico-minus-blue no-repeat scroll 95% 50% / 30px 30px;
		@include media($narrow) {
			background-position: 98% 50%;
		}
	}
	&.collapsed .fieldset-legend {
		background: $ico-plus-blue no-repeat scroll 95% 50% / 30px 30px;
		@include media($narrow) {
			background-position: 98% 50%;
		}
	}
}

.form-managed-file {
	input.form-file {
		float: left;
		margin: 0 15px 10px 0;
		width: 30%;
		border: $form-border;
		padding: 8px;
		min-width: 400px;
		+ .form-submit {
			float: left;
		}
	}
	.image-widget-data {
		.form-item { padding: 0;}
	}
}

// MAILCHIMP
.content-additional #mc_embed_signup {
	margin-top: 30px;
}
.signup-main {
  overflow:hidden;
  #mc_embed_signup {
    background-color:$gainsboro;
    form {
      padding:30px 0 15px 0;
      text-align:center;
    }
    label {
      display:inline-block;
    }
    .clear {
      display:inline;
    }
    input.email {
      display:inline-block;
      padding:10px 20px;
      margin:0 0 0 40px;
      min-height: 44px;
      max-width:320px;
      border-radius:0;
      border: 1px solid $gray_blue;
    }
    input.button {
      display:inline-block;
      background-color:$sunset_orange;
      font-size:1.125rem;
      font-family:$base-font-family;
      height:44px;
      width:100px;
      border-radius:0;
      margin:-5px 0 0 -5px;
    }
    #mce-responses {
      float:left;
      margin: 10px auto 20px;
      width: 100%;
    }
  }
}

.signup-test {
  overflow:hidden;
  #mc_embed_signup {
    background-color:$gainsboro;
    form {
      padding:30px 0 15px 0;
      text-align:center;
    }
    label {
      display:inline-block;
    }
    .clear {
      display:inline;
    }
    input.email {
      display:inline-block;
      padding:10px 20px;
      margin:0 0 0 40px;
      min-height: 44px;
      max-width:320px;
      border-radius:0;
      border: 1px solid $gray_blue;
    }
    input.button {
      display:inline-block;
      background-color:$sunset_orange;
      font-size:1.125rem;
      font-family:$base-font-family;
      height:44px;
      width:100px;
      border-radius:0;
      margin:-5px 0 0 -5px;
    }
    #mce-responses {
      float:left;
      margin: 10px auto 20px;
      width: 100%;
    }
  }
}
