.section-row {

	&.img-left {
		.card-body,
		.card-description {
			ul {
				overflow: hidden;
			}
		}
	}
	
	&.img-centre,
	&.vid-centre {
		.section-inner {
			.card-original,
			.card-video {
				width:100%;
				margin-bottom:1rem;
				text-align: center;
				
				.caption {
					display: inline-block;
				
					img {
						max-width:100%;
						height:auto !important;
						margin:0 auto;
					}
				}
				iframe {
					width:100%;
					margin:0 auto;
				}
			}
		}
	}

		&.img-left,
		&.vid-left,
		&.img-right,
		&.vid-right {
			.section-inner {
				.card-original, 
				.card-video {
					text-align: center;
				}
			}
		}
	
	
	@media(min-width:$narrow) {
		&.img-left,
		&.img-right {
			.section-inner {
				.card-original{
					max-width:calc(50% - 0.625rem);
					margin-bottom:1rem;
				}
			}
		}

		&.vid-left,
		&.vid-right {
			.section-inner {
				.card-video {
					width:calc(50% - 0.625rem);
					margin-bottom:1rem;
				}
			}
		}

		&.img-left,
		&.vid-left {
			.section-inner {
				.card-original, 
				.card-video {
					float:left;
					margin-right:1.25rem;
				}
			}
		}
		&.img-right,
		&.vid-right {
			.section-inner {
				.card-original,
				.card-video {
					float:right;
					margin-left:1.25rem;
				}
			}
		}
	}
}