.hp-intro {
  background: white $pattern-dark-3;
  box-shadow: $box-shadow;
  position: relative;
  margin-bottom: 70px;
  .view {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    .views-row {
      min-height: 476px;
    }
    .card-title,
    .card-description {
      @include media($narrow) {
        width: calc(100%/12 * 5);
        margin-left: calc(100%/12);
        position: relative;
        z-index: 1;
      }
    }
    .card-title {
      padding: 20px 20px 0 20px;
      @include media($narrow) {
        padding: 20px 0 0 0;
      }
      @include media($normal) {
        padding: 40px 0 0 0;
      }
      @include media(1200px) {
        padding: 50px 0 0 0;
      }
    }
    .card-description {
      padding: 0 20px 20px 20px;
      @include media($narrow) {
        padding: 0;
      }
    }
    .card-intro-block {
      width: 600px;
      margin: 0 auto;
      @include media($narrow) {
        height: 436px;
        position: absolute;
        top: 0;
        z-index: 0;
        right: 0;
        width: 606px;
        margin-right: -180px;
      }
      @include media($normal) {
        width: 656px;
      }
      @include media(1200px) {
        margin-right: 0;
      }
    }
  }
}
.svg-map-link {
  cursor: pointer;
  &:hover {
    .st2 {
      fill: $yellow_pantone;
    }
  }
}
