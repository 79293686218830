.tabs {
  &.primary {
    @include clearfix;
    line-height: 1.5;
    padding: 0 $horz-space/2;
    margin: $vert-space 0 0;
    border-bottom: $tab-border-bottom;

    li {
      list-style: none;
			display: inline-block;
			width: auto;

      a {
        background-color: $tab-inactive-bg-color;
        border-top: none;
        color: $tab-inactive-text-color;
        display: inline-block;
        font-weight: 400;
        padding: $vert-space/4 $gutter/2;
        text-decoration: none;

        @include media($narrow) {
          //@include inline-block;
					display:inline-block;
          border-top: 0;
        	padding: ($vert-space / 2) $gutter;
        }

        &:hover {
          background: $tab-hover-bg-color;
          color: $tab-hover-text-color;
        }

        &:focus {
          outline: none;
        }

        &.active {
					border: $tab-active-border;
					border-bottom-color: $tab-active-bg-color;
					background: $tab-active-bg-color;
					color: $tab-active-text-color;
          margin-bottom: -1px;
        }
      }

      .tab-content {
        display: none;
        padding: $vert-space $gutter;
        width: 100%;

        @include media($narrow) {
          border-top: $tab-border;
          float: left;
        }
      }
    }
  }
}
