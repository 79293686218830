/*ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0;

  &%default-ul {
    list-style-type: disc;
    margin-bottom: $vert-space/2;
    padding-left: $horz-space;
  }

  &%default-ol {
    list-style-type: decimal;
    margin-bottom: $vert-space/2;
    padding-left: $horz-space;
  }
}*/

ul, ol {
	margin-bottom: $vert-space;
	padding-left: $horz-space;
}

ul {
	list-style-type: disc;
	list-style: none;
	margin-left: 0;
	padding-left: 0;
}
ol {
	list-style-type: decimal;
}

.item-list ul {
	list-style: none;
	margin: 0;
	padding: 0;
	
	li {
		margin-left:0;
		padding-left:0;
	}
}
.item-list .field-content ul {
  //list-style: disc;
  margin-left: 20px;
}
dl {
  margin-bottom: $vert-space/2;

  dt {
    font-weight: 700;
    margin-top: $vert-space/2;
  }

  dd {
    margin: 0;
  }
}

.card-body,
.card-description,
.card-description-et,
.faq-question {
	ol,
	ul {
		margin-bottom:$vert-space;
		padding-left:0; //$horz-space;
		
		&.larger-text {
			padding-left: 0;
		}
	}
	
	ul {
		list-style-type: disc;

		list-style: none;

		li {
			padding-left:20px;

			&::before {
				content: "•"; 
				color: $sunset_orange;
				display: inline-block; 
				width: 1em;
				margin-left: -1em;
			}
		}
	}
	
	ol {
		list-style-type: decimal;

		list-style: none;
		counter-reset: li;
		
		list-style-position:inside; margin-left: 2px; display: table;

		li {
			counter-increment: li;
			padding-left:20px;
			
			margin-left: 13px; text-indent: -12px;

			&::before {
				content: counter(li);
				color: $sunset_orange;
				display: inline-block;
				width: 15px;
				margin-left: -5px;
				font: 600 1rem/1 $base-font-family;
			}
		}
	}
	
	p + ol,
	p + ul {
		margin-top:-10px;
	}
	* + h2,
	* + h3,
	* + h4,
	* + h5,
	* + h6 {
		margin-top:30px;
	}
	
}

