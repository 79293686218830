.leaflet-popup-hidden, .leaflet-control-attribution { display:none; }
.leaflet-popup-content > div { width:220px; }

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
	background-color:transparent;
	
	div {
		background: $map-pin-default no-repeat scroll 50% 50% / 50px 40px;
	}
}
.marker-cluster-medium div { background-size: 55px 44px; }
.marker-cluster-large div { background-size: 62px 50px; }


.marker-cluster {
	span {
		line-height: 36px;
		font-size:13px;
	}
	div {
		height:50px;
		width:40px;
		margin-left:0;
		margin-top:-20px;
	}
}

.leaflet-shadow-pane {
	display:none;
}

.leaflet-container a.leaflet-popup-close-button {
	color: $davys_grey;
	background-color:$anti_flash_white;
	font-size:20px;
	height:28px;
	padding:5px 3px 4px 4px;
	width:28px;
	&:hover {
		color: $davys_grey;
	}
}

.leaflet-popup {
	bottom:-5px;
	.leaflet-popup-content-wrapper {
		background-color:white;
		box-shadow:none;
		border-radius:0;
		border-bottom:solid 3px $sunset_orange;
		.leaflet-popup-content {
			margin:0;
			@media (min-width:540px) {
				width:400px !important;			
			}

			>div {
				width:auto;
			}
			.popup-card {
        &.alert {
          padding:20px;
          font-size:1rem;
        }
				.leaflet-popup-header {
					border-bottom: 1px solid $lavender_mist;
					width:100%;
					padding:20px 20px 10px;
					padding:0 20px 0;
					h4.card-title,
					h5.card-title {
						max-width:60%;
						display:inline-block;
						border-right:1px solid $lavender_mist;
						padding: 20px 20px 10px 0;
						margin: 0;
						word-break: break-word;
					}
					.card-membership-level {
						@media (max-width:540px) {
							margin: 5px 0 10px;
							width: auto;
						}
						
						float:right;
						margin: 10px 20px 10px 0;
						width:30%;
						.icon-Gold,
						.icon-Silver,
						.icon-Bronze {
							&:after {
								margin-top:-35px;
								@media (max-width:540px) {
									width: 100%;
									float: none;
									text-align: center;
									margin-top: 5px;
								}
							}							
						}
						.member-level {
							font-weight:700;
							text-transform:lowercase;
							font-size:14px;
							max-width:85px;
							margin-right:45px;
							text-align:center;
							@media (max-width:540px) {
								margin-right: 0;
								max-width: 70px;
							}
						}
					}
				}
				.leaflet-popup-main {
					padding:10px 20px;
					width:100%;
					.directory-tag {
						margin-top:0;	
					}
					.popup-organisation,
					.popup-deadline,
					.popup-job {
						margin-bottom: 10px;
					}
					.popup-organisation{
						font-weight: 700;
					}
				}
				.leaflet-popup-footer {
					width:100%;
					padding:0 20px 20px;
					.directory-tag {
						float:none;	
					}
				}
			}
			.popup-card.Basic {
				h4.card-title {
					max-width:100%;
					border-right:none;
				}
				.card-membership-level {
					display:none;
				}
			}
		}
	}
	.leaflet-popup-tip-container {
		margin-left:-21px;
		.leaflet-popup-tip {
			background:$sunset_orange;
		}
	}
}

// MAPS ON NODES

.page-node {
	.leaflet-popup {
		bottom:-3px !important;
		//left:-99px;
		.leaflet-popup-content-wrapper .leaflet-popup-content {
			width:200px !important;
			.popup-card .leaflet-popup-header { 
				h5.card-title {
					border-right:none;
					max-width:100%;
				}
			}
		}
		.leaflet-popup-tip-container {
			margin-top: -1px;
		}
	}
}


// ALERTS STYLES
.leaflet-card-type {
	padding: 10px 0;
	display: inline-block;
}
.leaflet-card-date {
	float: right;
	margin: 10px 25px 10px 10px;
}
.leaflet-card-tags { margin-bottom: 10px; }
