.view-attachments,
.view-display-id-attachments {
	
	// /* LAYOUT */
	ul {
		padding:0;
		margin:0;
	}
	
//	.views-row {
//		padding: $vert-space/2 0;
//	}
	
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}

	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space;
		
		.file-icon {
			float:left;
			margin:0 $horz-space 0 0;
			min-width:4rem;
			.file-extension {
				padding:$vert-space*0.3 $horz-space/4;
				text-transform:uppercase;
				font-size:0.75em;
        line-height:1;
				text-align:center;
				font-weight:700;
			}
			.file-size {
        padding: 3px 5px;
				font-size:0.750em;			
				text-align:center;
				color:$secondary;
        border:$secondary-border;
        border-top:none;
				background-color:white;
			}
		}
		
		.file-name {
			overflow:hidden;
		}
	}


	// /* FILE TYPE COLOURS */
  
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:$docx_color;
      color:white;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:$xlsx_color;
      color:white;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:$pptx_color;
      color:white;
    }
  }
  .file-type-pdf {
    .file-extension {
      background:$pdf_color;
      color:white;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:$txt_color;
      color:white;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:$jpg_color;
      color:white;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension { 
      background:$zip_color;
      color:white;
    }
  }
} 

.postscript-first {
   .view-attachments,
  .view-display-id-attachments {

    .attachment-link {
      background-color:white;

      .file-name {
        padding-top:5px;
      }
    }
  } 
}
