.prefix-wrapper-yellow {
	.prefix-wrapper {
		background: $middle_yellow $pattern-dark-6;
	}
}

.prefix-wrapper-blue {
	.prefix-wrapper {
		background: $deep_koamaru $pattern-light-3;
		h1 {
			color: $white;
		}
	}
}

.prefix-wrapper-white {
	.prefix-wrapper {
		background: white $pattern-dark-6;
	}
}

.prefix-wrapper {
	box-shadow: inset 0 -3px 4px -2px $box-shadow-rgba;
	
	.outer-wrapper {
		padding:40px 20px 30px
	}
	
	.prefix-yellow-inner {
		background: $middle_yellow $pattern-dark-6;
	}
	h1#page-title {
		visibility: hidden;
		font-size: 0;
		line-height: 0;
		height: 0;
	}

	.directory-title.block-block {
		padding: 32px $gutt/2 13px;	
		width: 100%;
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
	}

	h1.directory-title,
	.directory-title {
		padding: 0 0 14px;
		h2 {
			margin: 5px 0;
			font: normal 2.5rem/1 $title-font-family;
			line-height: 1.333;
			color: $deep_koamaru;
		}
	}
	
	.directory-subtitle {
		font-size:1.125em;
		margin-bottom:15px;
	}

	h1 {
		margin-bottom: 0;
		line-height: 1.333;
		font-size: 2.5rem;
	}

	.prefix-title {
		padding: 40px 20px 30px;
		@include outer-container($site-max-width);
	}

	.directory-header {
		width: 100%;
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
		padding: 32px $gutt/2 13px;
		overflow:hidden;
		min-height:190px;

		.header-title-wrapper {
			@include span-columns(9);
			margin-right:0;

			.member-details {
				float:left;
			}
		}
		.header-logo-wrapper {
			@include span-columns(3);
			margin-right:2.35765%;
			margin-bottom: 1em;
			float:right;
			.card-logo,
			.card-logo-1 {
				box-shadow:$box-side-shadow;
				display:inline-block;
				float: right;
				padding:20px;
				text-align: center;
				width: 100%;
				background: white;
			}
		}

		.card-type {
			color: $secondary;
			font: 700 0.875em/1.42857142857143 $title-font-family;
			margin-bottom: $vert-space/2;
			text-transform: uppercase;
		}

		.card-partner-states-er {
			font-size:1.125em;
			margin-bottom:15px;
		}
		.parent-org, {
			margin-bottom:15px;
		}
		.card-membership-level,
		.card-membership-level-1 {
			margin: -15px 0 15px;

			.icon-Bronze:after {
				float:none;
				position:relative;
				left: -115px;
					top: 10px;
			}

			.icon-Silver:after {
				float:none;
				position:relative;
				left: -105px;
				top: 10px;
			}

			.icon-Gold:after,
			.icon-Gold-EAHRC:after {
				float:none;
				position:relative;
				left: -95px;
				top: 10px;
			}

			.member-level {
				display:inline-block;
				text-transform: lowercase;
				font-weight:700;
				color:$primary;
				position:relative;
				left: 40px;
			}
		}
	}
	
	.block-menu-block {
		width: 100%;
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
		padding: 0px 15px;
		
		.main-menu-btn {
			display: none;
		}
		.menu-block-wrapper {
			position: relative;
			button {
				top: 0;
				height: 30px;
				width: 30px;
				transform: translate(0, 25%);
				border-radius: 50%;
				z-index: 1;
				&:before {
					display: none;
				}
				&.slick-prev {
					left: 10px;
					background: $sunset_orange $arrow-white-left no-repeat scroll 50% 50% / 8px 15px;
				}
				&.slick-next {
					right: 10px;
					background: $sunset_orange $arrow-white-right no-repeat scroll 50% 50% / 8px 15px;
				}
				&.slick-disabled {
					background: none;
				}
			}
			ul.menu {
				padding: 0 50px;
			}
			li a {
				margin-top: 0;
			}
		}
		> ul.menu {
			display: flex !important;
		}
	  ul.menu {
			border: none;
			list-style: none;
			padding: 0 10px;
			margin: 0;
			background: none transparent;


			li {
				border:none;
				display: inline-block;
				&.active {
					order: -1;
				}

				a {
					border-radius: 2px 2px 0 0;
					background-color: $anti_flash_white;
					margin-right: 3px;
					color: $primary;
					font: 1.125rem/1.3 $title-font-family;
					padding: 12px 25px;
					margin-top: 5px;
					box-shadow: inset 0 -2px 6px -3px $box-shadow-rgba;
					display: block;

					&:hover {
						background-color: $ghost_white;
						color: $sunset_orange;
					}

					&.active,
					&.active-trail {
						background-color: $white;
						box-shadow: 0 0px 6px 0px $box-shadow-rgba;

						&:hover {
							background-color: $white;
							color: $sunset_orange;
						}
					}	
				}
				&.slick-slide {
					margin-left: 1px;
					margin-right: 1px;
				}
				&.active-trail ul.menu {
					display: none;
				}
			}
		}
	}
}

.landing-page.search-page-view.full_width_main {
	&.prefix-wrapper-white,
	&.prefix-wrapper-blue {
	.prefix-wrapper {
		//margin-bottom: 30px;
		h1#page-title {
			line-height: 1.333;
			font-size: 2.5rem;
			height: auto;
			visibility: visible;
		}		
	}		
}

//	.view-filters {
//		@include outer-container($site-max-width);
//		padding: 0 10px ;
//	}
//	.outer-wrapper section#content .views-exposed-form {
//		padding-left: 20px;
//		padding-right: 20px;
//
//	}


	
}