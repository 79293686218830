.page-node-260 {
  .prefix-wrapper {
    min-height: auto;
    .view-display-id-title {
      min-height: auto;
    }
  }
  article h1 {
    display: none;
  }
}
