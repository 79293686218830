.node-type-country {

  .prefix-wrapper {
    .prefix {
      max-width: 1600px;
      margin: 0 auto;
      @include media($normal) {
        display: flex;
        align-items: flex-end;
      }
      .block-views {
        display: inline-block;
        vertical-align: bottom;
      }
      .country-flag {
        @include media($normal) {
          margin: 40px 80px -48px 15px;
        }
        margin: 40px 0 -68px 15px;
        .view-display-id-flag {
          .card-national-flag {
            max-width: 160px;
          }
          img {
            display: block;
          }
        }
      }
      .country-stats {
        @media (min-width:$narrow) and (max-width:1221px) {
          .view-display-id-stats {
            .view-content {
              .views-row {
                min-width: 0 !important;
              }
            }
          }
        }
        margin: 0px 15px -290px 15px;
        @include media($narrow) {
          margin: 0 15px -140px 15px;
        }
        @include media(957px) {
          margin-bottom: -38px;
        }
        .view-display-id-stats {
          @include media($narrow) {
            box-shadow: $box-shadow;
          }
          .view-content {
            display: inline-block;
            @include media($narrow) {
              display: flex;
              .views-row {
                min-width: 237px;
              }
            }
            .views-row {
              background: $white;
              border-left: 3px solid $true_blue;
              padding: 10px 14px;
              width: 49%;
              color: $davys_grey;
              float: left;
              margin-bottom: 2%;
              &.views-row-odd {
                margin-right: 2%;
                @include media($narrow) {
                  margin-right: 0;
                }
              }
              @include media($narrow) {
                margin-bottom: 0;
              }
              .card-title {
                text-transform: uppercase;
                line-height: 18px;
                font-size: 0.875rem;
              }
              .card-statistic {
                line-height: 30px;
                font-size: 1.625rem;
                font-weight: 600;
								word-wrap: break-word;
              }
            }
          }
        }
      }
    }
  }

  main {
    section#content {
      .content-main {
        padding: 0;
      }
    }
    padding-top: 260px;
    @include media($narrow) {
      // padding-top: 79px;
      padding-top: 170px;
    }
    padding-bottom: 0;
    .card-full-width-image {
      margin-bottom: 30px;
    }
    .view-display-id-bg {
      box-shadow: $box-shadow;
      .card-qa-question {
        font-size: 2.125rem;
        line-height: 39px;
        padding: 20px 30px;
        color: $deep_koamaru;
        font-weight: normal;
      }
    }
  }

  aside#sidebar-second {
    .view-id-partner_state_node.view-display-id-map {
      padding: 0;
      h2 {
        margin: 0;
      }
      img {
        display: block;
        margin: 0 auto;
      }
    }
  }

  .postscript-first {
    .postscript_first {
      .box-diamond-outer {
        padding: 0;
        .diamond-box-wrapper {
          margin: 0 10px 20px 10px;
          @include media($narrow) {
            @include span-columns(4);
            margin-bottom: 0;
            margin-left: 0;
          }
        }
      }
      .diamond-list {
        h3 {
          font-size: 2.125rem;
        }
      }
      .block-views h2 {
        font-size: 2.125rem;
        text-align: center;
        color: $deep_koamaru;
      }
    }
  }
}
