.page-user {
	
	h1#page-title,
	.view-display-id-title .views-row {
		display:none;
	}
	
	.prefix .prefix-yellow-inner {
		display: none;
		&.profile-dir-prof { display: block; }
	}
	
  	// User profile info
	.view-display-id-profile {
		.views-row {
			background: $white;
			overflow: hidden;
		}
		.card-photo {
			padding: 30px 30px 0 30px;
			@media (min-width:880px) {
				@include span-columns(4);
				float:right;
				margin-right: 0;
				padding: 30px 30px 30px 0;
			}
		}
		.node-details-wrapper {
			overflow: hidden;
			.node-details {
				background-color:white;
				padding:10px 20px 20px 20px;
			
				.profile-job,
				.profile-bio-title {
					font-size: 2.5em;
					color:$deep_koamaru;
					margin-bottom:15px;
				}
			}
		}
		.special-list {
			display: inline-block;
			li {
		    margin-right: 10px;
		    margin-top: 10px;
		    padding: 2px 10px;
			}
		}
	}

  .top-content-link {
    margin-bottom: 10px;
  }
  a.content-link {
    margin-bottom: 10px;
    display: block;
    &:before {
      background: $ico-plus-blue no-repeat scroll 50% 50% / 14px 14px;
      display: inline-block;
      height: 22px;
      width: 22px;
      content: " ";
      vertical-align: top;
      margin-right: 5px;
    }
    &:hover {
      &:before {
        background: $ico-plus-red no-repeat scroll 50% 50% / 14px 14px;
      }
    }
  }
  
	.profile-org,
	.profile-partner-state,
	.profile-bio-text,
	.card-primary-phone-number,
	.card-email,
	.card-fax,
	.card-mob-cell {
		margin-top:10px;
	}
	.profile-bio {
		@media (max-width:879px) {
			margin:20px 0;
		}
	}
	.profile-bio-text p:last-child { 
		margin-bottom:0;
	}
	
	.card-email {
		font-weight:500;
	}
}

.page-my-account-content-management h1#page-title {
  margin-left:20px;
}


// ADD CONTENT LINKS

a.content-link {
  display: inline-block;
  &:before {
    background: $ico-plus-blue no-repeat scroll 50% 50% / 14px 14px; 
    content: " ";
    display: inline-block;
    height: 22px;
    margin-right: 5px;
    width: 22px;
  }
  &:hover {
    &:before {
      background: $ico-plus-red no-repeat scroll 50% 50% / 14px 14px;
    }
  }
}

.content-links-rhs {
  a.content-link {
    margin-bottom: 10px;
    &:before {
      vertical-align: top;
    }
  }
}

.content-links-main {
  margin:20px 0;
  background-color: white;
  padding: 20px;
  a.content-link {
		border: $form-border;
		margin: 0 5px 5px 0;
		padding: 5px 15px 5px 10px;
    &:before {
      background-size: 16px 16px;
      vertical-align: text-bottom;
    }
    &:hover {
				border-color: lighten($sunset_orange,20%);
      &:before {
        background-size: 16px 16px;
      }
    }
  }
}

.page-user:not(.page-user-login):not(.page-user-register):not(.page-user-register):not(.page-user-password):not(.role-administrator):not(.role-editor) .tabs.outer-wrapper {
  display:none;
}

.page-node-6527 .messages.status { display:none; }