@media (min-width:$wide) {
	.no-sidebars .node-content .card-summary,
	.no-sidebars .node-content .card-intro,
	.no-sidebars .node-content .card-body,
	.no-sidebars .node-content .card-authors {
		padding:0 16% 0 0;
	}
}
.node-leaf {
  main {
    background-color: $main-page-bg;
  }
  #content {
    padding: $vert-space;
    background-color: white;
		overflow:hidden;
    
    .card-title-field h1 {
      font-size: 2rem;
      font-weight: normal;
    }
    .card-intro {
      margin-top: $vert-space;
    }
    .card-image {
      float: left;
      margin: 0 $vert-space $horz-space 0;
      
      @media(min-width:640px){
        max-width:55%;
      }
    }
  }
}

main section#content {
	padding: 0 20px;
	@include media($narrow) {
		.content-main,
		.content-additional {
			// padding: 0;
		//	padding: 0 20px;
		}		
	}
	.content-main + .content-additional {
		margin-top: 0;
	} 
}
	
.main-content-white-bg:not(.landing-page) {
	section#content {
		background: $white;
		padding: 30px;
		overflow: hidden;
		
		.section-row {
			padding: 0 0 15px;
		}
		
		.content-main,
		.content-additional {
			padding: 0;
		}
	}
	
	&.no-sidebars {
		section#content {
			margin: 0 15px;
			width: calc(100% - 30px);
		}		
	}
}

.footer-prefix {
  background-color:$gainsboro;
}

.node-content {
	h1 .card-title-field {
		font-size:2.5rem;
		line-height:1.1875;
		color:$deep_koamaru;
		font-family:$title-font-family;
		margin:0 0 0;
		text-transform:none;
	}
	.card-image {
		margin-bottom:$vert-space;
	}
	.card-display-date {
		font-size:0.875rem;
		margin-right:3px;
		padding:0;
	}
	.card-location {
    display: inline-block;
    font-size: 0.875rem;
	}
	.card-date,
	.card-display-date,
	.card-location {
		margin-bottom:$vert-space*0.75;
	}
	.card-job-title {
		color:$secondary;
		font-size:1.125em;
	}
  .card-summary {
    color:$primary;
    font-size:1.25em;
    font-weight:700;
    line-height:1.3;
  }
	.label {
		color:$deep_koamaru;
	}
  .card-link {
  	margin-bottom:$vert-space;
  }
		
	&.node-publication {
		.card-journal,
		.card-year,
		.card-volume,
		.card-pages,
		.card-citation-date {
			display:inline-block;
		}
		.card-authors,
		.card-abbr-authors {
			font-style:italic;
			margin-bottom:$vert-space/4;
			display: inline;
		}
		.card-journal:after {
			content:", ";
			margin-left:-3px;
		}
		.card-citation-date:after {
			content:"; ";
			margin-left:-3px;
		}
		.card-volume:after {
			content:": ";
			margin-left:-3px;
		}
		.card-journal {
			font-size: 1em;
		}
	}
	&.node-organisation {
		.card-logo {
			text-align: center;
			background-color: transparent;
			margin-bottom:1rem;
			
			@media(min-width:$narrow) {
				float:right;
				margin-left:1.25rem;
				max-width:calc(50% - 0.625rem);
			}
		}
	}
	&.node-event,
	&.node-news {
		.view-health-country-tags {
			float: left;
			margin-bottom:10px;
			@media (min-width:540px) {
				margin-bottom:30px;
			}
			.views-row {
				float: left;
			}
			&.view-display-id-countries {
				clear: both;
			}
		}
	}
	&.node-event {
		h1 {
			clear: left;
		}
		.card-event-type {

			float: left;
			line-height: 1;
		}
		.card-event-coverage {
			float: right;
			margin-bottom: 0;
		}
		.card-event-type {
			font-weight: 600;
			line-height: 1;
			margin-bottom: 15px;
			color: $nickel;
		}
		.card-display-date,
		.card-location,
		.card-link {
			float:none;
			margin-bottom:20px;
			font-size: 1rem;
			color: $nickel;
			.label {
				font-weight: 600;
				color: $deep_koamaru;
				font-size: 1.250rem;
			}
			@include media($narrow) {
				@include span-columns(4);
				margin-right: 1%;
				display: inline-block;
			}
		}
		.card-display-date {
			line-height: 1.57142857142857;
		}
		ul.links.inline {
			clear: both;
		}
	}
}

.node-type-news main {
	.card-news-type {
		margin-bottom:10px;
	}
}

.prefix-wrapper-yellow {
	.prefix-wrapper {
		background: $middle_yellow $pattern-dark-6;
  }
}

.prefix-wrapper-blue {
	.prefix-wrapper {
		background: $deep_koamaru $pattern-light-3;
		h1 {
			color: $white;
		}
	}
}

.prefix-wrapper-white {
	.prefix-wrapper {
		background: white $pattern-dark-6;
		// min-height:190px;
	}
}



.prefix-wrapper {
	box-shadow: inset 0 -3px 4px -2px $box-shadow-rgba;
	
	.outer-wrapper {
		padding:40px 20px 30px
	}
	
	.prefix-yellow-inner {
		background: $middle_yellow $pattern-dark-6;
	}
	h1#page-title {
		visibility: hidden;
		font-size: 0;
		line-height: 0;
		height: 0;
	}

	.directory-title.block-block {
		padding: 32px $gutt/2 13px;	
		width: 100%;
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
	}

	h1.directory-title,
	.directory-title {
		padding: 0 0 14px;
		h2 {
			margin: 5px 0;
			font: normal 2.5rem/1 $title-font-family;
			line-height: 1.333;
			color: $deep_koamaru;
		}
	}
	
	.directory-subtitle {
		font-size:1.125em;
		margin-bottom:15px;
	}

	h1 {
		margin-bottom: 0;
		line-height: 1.333;
		font-size: 2.5rem;
	}

	.prefix-title {
		padding: 40px 20px 30px;
		@include outer-container($site-max-width);
	}

	.directory-header {
		width: 100%;
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
		padding: 32px $gutt/2 13px;
		overflow:hidden;
		min-height:190px;

		.header-title-wrapper {
			@include span-columns(9);
			margin-right:0;

			.member-details {
				float:left;
			}
		}
		.header-logo-wrapper {
			@include span-columns(3);
			margin-right:2.35765%;
			margin-bottom: 1em;
			float:right;
			.card-logo,
			.card-logo-1 {
				box-shadow:$box-side-shadow;
				display:inline-block;
				float: right;
				padding:20px;
				text-align: center;
				width: 100%;
				background: white;
			}
		}

		.card-type {
			color: $secondary;
			font: 700 0.875em/1.42857142857143 $title-font-family;
			margin-bottom: $vert-space/2;
			text-transform: uppercase;
		}

		.card-partner-states-er {
			font-size:1.125em;
			margin-bottom:15px;
		}
		.parent-org {
			margin-bottom:15px;
		}
		.card-membership-level,
		.card-membership-level-1 {
			margin: -15px 0 15px;

			.icon-Bronze:after {
				float:none;
				position:relative;
				left: -115px;
					top: 10px;
			}

			.icon-Silver:after {
				float:none;
				position:relative;
				left: -105px;
				top: 10px;
			}

			.icon-Gold:after,
			.icon-Gold-EAHRC:after {
				float:none;
				position:relative;
				left: -95px;
				top: 10px;
			}

			.member-level {
				display:inline-block;
				text-transform: lowercase;
				font-weight:700;
				color:$primary;
				position:relative;
				left: 40px;
			}
		}
	}
	
	.block-menu-block {
		width: 100%;
		max-width: 1600px;
		margin-left: auto;
		margin-right: auto;
		padding: 0px 15px;
		
		.main-menu-btn {
			display: none;
		}
		.menu-block-wrapper {
			position: relative;
			button {
				top: 0;
				height: 30px;
				width: 30px;
				transform: translate(0, 25%);
				border-radius: 50%;
				z-index: 1;
				&:before {
					display: none;
				}
				&.slick-prev {
					left: 10px;
					background: $sunset_orange $arrow-white-left no-repeat scroll 50% 50% / 8px 15px;
				}
				&.slick-next {
					right: 10px;
					background: $sunset_orange $arrow-white-right no-repeat scroll 50% 50% / 8px 15px;
				}
				&.slick-disabled {
					background: none;
				}
			}
			ul.menu {
				padding: 0 50px;
			}
			li a {
				margin-top: 0;
			}
		}
		> ul.menu {
			display: flex !important;
		}
	  ul.menu {
			border: none;
			list-style: none;
			padding: 0 10px;
			margin: 0;
			background: none transparent;


			li {
				border:none;
				display: inline-block;
				&.active {
					order: -1;
				}

				a {
					border-radius: 2px 2px 0 0;
					background-color: $anti_flash_white;
					margin-right: 3px;
					color: $primary;
					font: 1.125rem/1.3 $title-font-family;
					padding: 12px 25px;
					margin-top: 5px;
					box-shadow: inset 0 -2px 6px -3px $box-shadow-rgba;
					display: block;

					&:hover {
						background-color: $ghost_white;
						color: $sunset_orange;
					}

					&.active,
					&.active-trail {
						background-color: $white;
						box-shadow: 0 0px 6px 0px $box-shadow-rgba;

						&:hover {
							background-color: $white;
							color: $sunset_orange;
						}
					}	
				}
				&.slick-slide {
					margin-left: 1px;
					margin-right: 1px;
				}
				&.active-trail ul.menu {
					display: none;
				}
			}
		}
	}
}


// DIRECTORY NODES 

.node-type-service:not(.page-node-edit),
.node-type-opportunity:not(.page-node-edit),
.node-type-product:not(.page-node-edit),
.node-type-research-program:not(.page-node-edit),
.node-type-academic-programme:not(.page-node-edit) {
  main section#content{
  	.content-main {
  		padding: 0;
  		box-shadow: $box-shadow;
  	}
  }
	.view-display-id-title .card-organisation-er {
		display:none;
	}
	.card-date-posted {
		display:none;
	}
	.view-display-id-profile {
		.view-content {
			background: $white;
			.views-row {
				display: inline-block;
				background: $white;
			}
		}
		.card-image,
		.card-product-image {
			@include media($narrow) {
				@include span-columns(7);
				padding: 30px;
				// background-color:white;
				margin-right: 0;
			}
		}
	}
	.card-product-type {
		margin-bottom:10px;
	}
	.card-price p {
		display:inline-block;
		margin-bottom:0;
	}
	.node-details-wrapper {
		overflow: hidden;

		.node-details {
			// background: #FFFFFF;
			padding: 30px;
			margin-bottom: $vert-space*1.5;
			.details-date {
				margin-bottom:10px;
			}
			.details-link {
				margin-bottom:10px;
			}
		}
	}
	.card-product-image + .node-details-wrapper {
		.node-details { padding-left: 0; }
	}	
}
.node-type-academic-programme:not(.page-node-edit) {
	section#content {
		box-shadow: $box-shadow;
	}
	.view-display-id-profile .views-row {
		display: block;
		float: left;
		width: 100%;
	}
}
.node-type-service,
.node-type-opportunity {
	.node-details-wrapper .node-details {
		padding: 30px;
	}
	.view-display-id-profile .views-row {
		display: block;
	}
	.healthcare-tags li.directory-tag {
		margin-right: 10px;
		margin-top: 10px;
		padding: 2px 10px;
	}
}

.node-type-event,
.node-type-news {
	main section#content {
		box-shadow: $box-shadow;
	}
}
.node-type-product {
	.view-id-product_node.view-display-id-gallery {
		position: relative;
		.view-footer {
			text-align: center;
		}
		// @include media($narrow) {
			// .view-footer {
			// 	position: absolute;
			// 	top: 50%;
			// 	width: 100%;
			// 	.slick-controls {
			// 		width: 100%;
			// 		button.slick-arrow {
			// 			&.slick-prev {
			// 				left: 40px;
			// 			}
			// 			&.slick-next {
			// 				right: 40px;
			// 			}
			// 		}
			// 		.slick-dots {
			// 			bottom: -240px;
			// 		}
			// 	}
			// }
		// }
	}
	.view-id-directory_header {
		.card-partner-states-er {
			display: none;
		}
	}
}

.page-node-557 {
	.prefix-wrapper {
		min-height: auto;
	}
	h1 .card-title-field {
		display: none;
	}
}

.cursor {
	cursor: pointer;
}

// MEMBER ICONS

.icon-Gold:after,
.icon-Gold-EAHRC:after {
	content:" ";
	background: $ico-gold no-repeat scroll 50% 50% / 30px 30px;
	height:30px;
	width:30px;
	display:inline-block;
	float:right;
}
.icon-Silver:after {
	content:" ";
	background: $ico-silver no-repeat scroll 50% 50% / 30px 30px;
	height:30px;
	width:30px;
	display:inline-block;
	float:right;
}
.icon-Bronze:after {
	content:" ";
	background: $ico-bronze no-repeat scroll 50% 50% / 30px 30px;
	height:30px;
	width:30px;	
	display:inline-block;
	float:right;
}

.icon-Basic {
	display:none;
}

// NODE DETAILS - RESOURCE/PUBLICATIONS

//DETAILS - NO RHS SIDEBAR, NO IMAGE

.detail-content-wrapper {
	.node-details-wrapper {
		@include span-columns(12);
		margin-bottom:20px;
		.detail-featured {
			@include span-columns(12);
			vertical-align:top;
			@media(min-width:720px) {
				@include span-columns(6);
				display: inline-block;
				margin-right: 1%;
			}
			.card-date-display-override {
				margin-bottom: 15px;
			}
			.label {
				display:block;
				font-size:1.25em;
				margin-bottom:5px;
				font-style:normal;
			}
		}
		.detail-sub {
			margin-bottom:15px;
			clear:both;

			.label {
				display: inline;
				margin-right:10px;
			}
		}
	}
}

// DETAILS + IMAGE
.detail-content-wrapper.has-cover-image {
	.node-details-wrapper {
		@include span-columns(12);
		margin-top:20px;

		@media (min-width:550px) and (max-width:719px) {
			@include span-columns(6);
			margin-top:0;
		}
		@media (min-width:720px) {
			@include span-columns(8);
			margin-top:0;
			width:70%;
		}
		.detail-featured {
			@include span-columns(12);
			vertical-align:top;
			@media(min-width:720px) {
				@include span-columns(4);
				display: inline-block;
				margin-right: 1%;
			}
		}
	}
	
	.node-cover-image .card-cover-image {
		@include span-columns(12);

		@media (min-width:550px) and (max-width:719px) {
			@include span-columns(5);
			&:last-child {
				margin-right:20px;
			}
		}

		@media (min-width:720px) {
			@include span-columns(3);
			&:last-child {
				margin-right:20px;
			}
		}
	}
}

// IMAGE + RHS

.two-sidebars {
	.detail-content-wrapper.has-cover-image {
		.node-details-wrapper {
			@include span-columns(12);
			margin-left:0;
			margin-top:-15px;
			@media (min-width:720px) and (max-width:939px) {
				@include span-columns(6);
				margin-top:0;
			}
			@media (min-width:940px) {
				@include span-columns(8);
				margin-top:0;
			}
			.detail-featured {
				@include span-columns(12);
				@media(min-width:940px) {
					@include span-columns(4);
				}
			}
		}

		.node-cover-image .card-cover-image {
			@include span-columns(12);
			margin-bottom:20px;

			@media (min-width:720px) and (max-width:939px) {
				@include span-columns(5);
				&:last-child {
					margin-right:20px;
				}
			}

			@media (min-width:940px) {
				@include span-columns(3);
				&:last-child {
					margin-right:20px;
				}
			}
		}
	}
}

// SESSION NODE DETAILS

.detail-content-wrapper.session {
	overflow: hidden;
    margin-bottom: 20px;
	.node-details-wrapper {
		@include span-columns(12);
		margin-bottom:0px;
		@media(min-width:940px) {
			margin-bottom:10px;
		}
		.detail-featured {
			@include span-columns(12);
			vertical-align:top;
			@media(min-width:720px) and (max-width:939px) {
				@include span-columns(6);
				display: inline-block;
				margin-right: 0;
				margin-bottom:20px;
			}
			@media(min-width:940px) {
				@include span-columns(3);
				display: inline-block;
				margin-right: 0;
			}
			.session-time {
				.card-start-hour,
				.card-end-hour {
					margin-right:-2px;
				}
				.card-start-minute,
				.card-end-minute {
					margin-left:-2px;
				}
				&> div {
					display:inline-block;
				}
			}
		}
	}
}

span.ext {
	display:none;
}
// Social accelerators
.soc-accelerators {
  h2 {
    box-shadow: $box-shadow;
    font-size: 1rem;
    font-weight: 600;
    line-height: 22px;
    text-transform: uppercase;
    padding: 10px 42px 12px 12px;
    color: $white;
    background: $sapphire_blue $ico-share no-repeat scroll 90% 50% / 18px auto;
    @include media($narrow) {
	    &:hover {
	      background: $action-hover $ico-share no-repeat scroll 90% 50% / 18px auto;
		    cursor: pointer;
	    }
    }
  }
  .card-links ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    box-shadow: $box-shadow;
    background: white $pattern-dark-6;
    display: inline-block;
    @include media($narrow) {
    	display: block;
    }
    li {
    	float: left;
      a {
        display: block;
        width: 20px;
        height: auto;
        overflow: hidden;
        text-indent: -999em;
        padding: 10px;
        box-sizing: content-box;
      }
      &.facebook a {
        background: $ico-facebook-red no-repeat scroll 50% 50% / auto 20px;
        &:hover {
          background: $ico-facebook-blue no-repeat scroll 50% 50% / auto 20px;
        }
      }
      &.twitter a {
        background: $ico-twitter-red no-repeat scroll 50% 50% / auto 20px;
        &:hover {
          background: $ico-twitter-blue no-repeat scroll 50% 50% / auto 20px;
        }
      }
      &.linkedin a {
        background: $ico-linkedin-red no-repeat scroll 50% 50% / auto 20px;
        &:hover {
          background: $ico-linkedin-blue no-repeat scroll 50% 50% / auto 20px;
        }
      }
      &.google a {
        background: $ico-gplus-red no-repeat scroll 50% 50% / auto 20px;
        &:hover {
          background: $ico-gplus-blue no-repeat scroll 50% 50% / auto 20px;
        }
      }
    }
  }
}

.soc-accelerators {
  position: relative;
  @media (max-width: $narrow) {
    h2 {
      float: left;
    }
    .card-links ul
    .card-links li {
      float: left;
    }
  }
  @include media($narrow) {
    h2 {
      position: absolute;
      left: 0;
      transition: left 100ms;
    }
    .card-links ul {
      position: absolute;
      left: 0;
      top: 0;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear;
    }
    &.open-share {
      h2 {
        position: absolute;
        left: 160px;
        transition: left 100ms;
      }
      .card-links ul {
        display: flex;
        visibility: visible;
        opacity: 1;
      }
    }
  }
  @media (min-width:$narrow) and (max-width:1110px) {
    h2 {
      text-indent: -999em;
      background-position: 50% 50%;
      // left: 10px;
      &:hover {
        background-position: 50% 50%;
      }
    }
  }
}

//HIDE MEMBERSHIP LEVEL FROM NON-EDITORS

#edit-field-membership-level {
	display:none;
}

//SEARCH PAGE VIEWS

.prefix-wrapper-blue.health-condition-subviews {
	h1#page-title {
		visibility: hidden;
		font-size: 0;
		line-height: 0;
		height: 0;
		margin: 0;
		padding: 0;
	}
}

.page-health-conditions.health-condition-subviews,
.node-type-health-condition {
	main {
		padding-top: 0;
		
		#sidebar-second {
			padding-top: 30px;
		}
		
		section#content .content-main,
		section#content .content-additional {
			padding-left: 0;
			padding-right: 0;
		}
		.view-health-condition-content .view-content {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	
	&.main-content-white-bg {
		section#content {
			padding: 30px 20px 0;

			.view-display-id-page_policy .views-row {
				margin-top:-15px;
			}
		}
	}
}

// ALERTS

.node-details .view-health-country-tags {
  margin-top:5px;
}

.alert-date.new:before {
  content:"|";
  display: inline-block;
    margin-left:4px;
  margin-right:10px;
}

.card-alert-date > div {
  display:inline-block;
}

.alert-desc {
	clear:both;
	padding-top: 20px;
}

.view-footer-controls .card-feed-link {
    margin-top: 15px;
}

.block-hide {
    display: none;
}