.ajax-progress ,ajax-progress-throbber, .flag-throbber {
	display:none;
}
body {
	.view {
		.view-filters {
			opacity:1;
			transition:opacity 0.25s ease-in-out;
			//background: $pale_grey_two;
			//padding: 1em;
			margin-bottom: 1em;
			// border: 1px solid $battleship_grey;
			.views-exposed-form {
				background-color: white;
    		padding: 20px;
				// margin: 0 10px 20px 0;
				margin-bottom: 20px;
				box-shadow:$card-shadow;
				.views-exposed-widgets {
					.views-exposed-widget {
						//background: $white;
						padding: 0;
						@include media($normal) {
							margin-right: 1em;
						}
						//border: 1px solid $pale_grey;
						> label {
							padding: 10px 20px;
							border-bottom: 2px solid $faded_blue;
						}
						.views-widget {
							//padding: 10px;
						}
					}
					#edit-keywords-wrapper {
						@include media($narrow) {
							margin-right: 1em;
						}
					}
					.views-submit-button {
						clear: both;
						//margin-top: 1em;
						input {
							margin-top: 0;
						}
					}
				}
			}
		}
		.view-header {
			//font-weight: 600;
		}
		.view-content {
			opacity:1;
			transition:opacity 0.5s ease-in-out;
		}
	}
	&.ajax-processing {
		.view {
			.view-filters {
				opacity:0.5;
			}
			.view-content {
				opacity:0;
			}
			* {
				cursor:wait;
			}
		}
	}
}

// NON-DIRECTORY SPECIFIC FILTERS

.view-id-news,
.view-id-policy_pubs,
.view-id-health_condition_content {
	.view-header {
		margin:20px 0 0;
		@media (max-width:429px) {
			margin:50px 0 0;
			display: inline-block;
		}
		@media (min-width:430px) and (max-width:539px) {
			margin:90px 0 20px;
		}
	}
	.view-filters {
		position:relative;
			.views-exposed-form {
			@media (max-width:539px) {
				margin-bottom:100px;
			}
			.form-item.form-type-select {
				.chosen-container {
					@media (max-width:539px) {
						width:100% !important;
						margin-bottom: 20px;
					}
				}
				&.form-item-sort-bef-combine { 
					display:block;
					position:absolute;
					right:-20px;
					bottom:-20px;
					width: auto;
					@media (max-width:539px) {
						position:relative;
						left:-20px;
						bottom:-40px;
					}
					@media (min-width: 540px) and (max-width: 720px) {
						right: 0;
					}
					label {
						display:inline;
						margin-right:10px;
					}
					.chosen-container {
						@media (max-width:539px) {
							width:auto !important;
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
}

select#edit-sort-bef-combine {
	display:inline;
	//background: white $arrow-grey-down no-repeat scroll 95% 50% /20px 20px;
}

// DIRECTORY SEARCH

.page-directory-search main #content #page-title {
	display: none;
	visibility: hidden;
	height: 0;
	margin: 0;
	font-size: 0;
}

.form-item.form-type-select.form-item-health-conditions {
    display: none !important;
}

.view #edit-secondary-wrapper legend a.fieldset-title {
	pointer-events: none;
}

.view {
	.view-filters {
		padding:0;	
		
		font-family:$title-font-family;
		font-size:1.25rem;
		font-weight:500;
		
		.views-exposed-widgets {
			display: flex;
			flex-flow: row wrap;
			position: relative;

			#edit-keywords-wrapper { 
				order: 1; 
				flex: 1 100%;
				@include media($narrow) {
					flex: 0 25%; 
				}
				@include media($normal) {
					flex: 0 20%; 
				}
			}
			#edit-organisation-wrapper { 
				order: 2; 
				flex: 2 100%;
				@include media($narrow) {
					flex: 0 25%; 
				}
				@include media($normal) {
					flex: 0 20%; 
				}
			}
			.views-submit-button { order: 3; flex: 3 100% }
			.views-reset-button { order: 4; flex: 4 100% }
			#edit-secondary-wrapper { order: 5; flex: 5 100%; }

			#edit-keywords-wrapper,
			#edit-search-wrapper,
			#edit-organisation-wrapper {
				input[type="text"] {
					box-shadow:none;
					height: 44px;
					font-size: 1.125rem;
					// border: $octonary-border;
					border: $primary-border;
					background-color: $form-bg-color;
					border-radius: $base-border-radius;
					background-position: 98% center;
					margin-bottom: 1.125em;
					width: 100%;
					@include media($narrow) {
						margin-bottom: 40px;
						max-width:340px;
					}
					&:not(.throbbing) {
						background-image: none;
					}
				}
			}


			fieldset.collapsible {
				border: none;
				padding:0;

				legend {
					margin-bottom:10px;
					margin-left: 15px;
				}

				span.fieldset-legend {
					background:none;
					a.fieldset-title {
						color:$deep_koamaru;
						font-family:$title-font-family;
						font-size:1.625rem;
						margin-left:-15px;
					}
				}
			}

			.views-exposed-widget {
				&.views-submit-button {
					margin-top: 0;
					height: 42px;
					margin-bottom: 1.125em;
					@include media($narrow) {
						// left: 370px;
						// position: absolute;
						// margin-bottom: 0;
						flex: 0;
						margin-right: 1em;
					}
					
					input[type="submit"] {
						background-color:$sunset_orange;
						border: $quaternary-border;
						width:100%;
						@include media($narrow) {
							width:120px;
						}
						
						&:hover {							
							background-color:$action-alt-hover;
							border: $tertiary-border;
						}
					}					
				}
				
				&.views-reset-button {
					height: 42px;
					margin-bottom: 1.125em;
					@include media($narrow) {
						// left: 500px;
						// position: absolute;
						// margin-bottom: 0;
						flex: 0;
					}
					
					input[type="submit"] {
						background-color:white;
						border:1px solid $sunset_orange;
						color:$sunset_orange;
						margin-top:0;
						width:100%;
						@include media($narrow) {
							width:120px;
						}
						
						&:hover {							
							color:$action-alt-hover;
							border: $tertiary-border;
						}
						
					}
				}
			}
		}
		
		.form-item.form-type-select {
			display: inline;
			float: left;
			width: 100%;
			@include media($narrow) {
				width: 50%;
				padding-right: 20px;
			}
			@include media($normal) {
				padding-right: 0;
				margin-right: 20px;
				width: auto;
			}

			& > label {
				color:$deep_koamaru;
				font-family:$title-font-family;
				font-size:1.125rem;
				font-weight:500;
			}
			
			select#edit-sort-bef-combine {
				display:inline-block;
			}

			.form-checkboxes .bef-checkboxes {
				border: $primary-border; 
				background-color: $form-bg-color;
				//padding: 15px 20px;
				padding:4px 15px 4px;
				display: inline-block;
				min-width:250px;
				border-radius: $base-border-radius;				
				width: 100%;
				@include media($narrow) {
					min-height: 42px;
				}

				.form-item { 
					display: flex;
					flex-direction: column;
					margin: 8px 0;
				}

				label.option {
					margin-bottom:0;
				}
				&:empty {
					background-color: $gainsboro;
				}
			}
		}
	}
}

// SERVICE SPECIFIC STYLE
#views-exposed-form-directory-services-page {
	.bef-secondary-options {
		// @include media($normal) {
			display:flex;
			flex-wrap: wrap;
			.form-item.form-type-select.form-item-specialised-healthcare { order: 1;}
			.form-item.form-type-select.form-item-type { order: 2;}
			.form-item.form-type-select.form-item-ownership { order: 3;}
			.form-item.form-type-select.form-item-partner-states { order: 4;}
			.form-item.form-type-select.form-item-organisations { order: 5;}
		// }
	}
}

// ALERTS SPECIFIC STYLE
#views-exposed-form-alerts-page-all {
	.bef-secondary-options {
		// @include media($normal) {
			display:flex;
			flex-wrap: wrap;
			.form-item.form-type-select.form-item-partner-state { order: 1;}
			.form-item.form-type-select.form-item-status { order: 2;}
			.form-item.form-type-select.form-item-disease { order: 3;}
		// }
	}
}

// EVENTS SEARCH SPECIFIC STYLE
.bef-secondary-options {
	display: flex;
	flex-wrap: wrap;
}
.view-filters .bef-secondary-options {
	.form-item {
		@media (max-width: $normal) {
			margin-bottom: 1em;
		}
	}
}

// Directory expandy filter styling
.directory-view .view-filters {
	.bef-secondary-options {
		@media (min-width: $narrow) and (max-width: $normal) {
			.form-item:nth-child(odd) {
				clear: left;
			}
		}
		.expand-filter > label {
			@media (max-width: $narrow) {
				background: $anti_flash_white $ico-plus-grey no-repeat scroll 95% 50% / 20px 20px;
				margin-bottom: 0;
				padding: 10px;
				line-height: 1.313rem;
				font-size: 1.125rem;
				border: 1px solid $gainsboro;

				&:hover {
					cursor: pointer;
				}
			}
			&.active {
				background: $anti_flash_white $ico-minus-grey no-repeat scroll 95% 50% / 20px 20px;
			}
		}
		.form-item {
			margin-bottom: 1.250em;
			@media (max-width: $narrow) {
				.form-checkboxes {
					display: none;
				}
			}
		}
	}
}

#views-exposed-form-directory-management-page {
  span.fieldset-legend {
    margin-left:15px;
  }
}
// CHOSEN
	
.chosen-container {
	width: 100% !important;
	//max-width: 200px;
	min-width:200px;
	@include media($normal) {
		width: auto !important;
	}
	&.chosen-container-multi .chosen-choices,
	&.chosen-container-single .chosen-single{

		border-radius: $base-border-radius;
		
		li.search-field {
			//float: none;
			input[type="text"] {
				color: $primary !important;
				font: 1rem/1.1875 $base-font-family;
				//padding: 0 20px;
				width: 100% !important;
			}
		}

		li.search-choice {
			margin: 3px 7px 3px 7px;
			padding: 5px 23px 5px 8px;
			border: none;
			background: white;
			box-shadow: none;
			color: $primary;
			line-height: 13px;
			cursor: default;
			font-size: 16px;
			font: 1rem/1 $base-font-family; 
			clear: both;

			.search-choice-close {
				top:7px;	
			}
		}	
			
		.search-choice.first.last {
			padding: 5px 8px;

			.search-choice-close {
				display: none;
			}
		}
		
		a.chosen-single:hover {
			color:#444444;
		}
	}
	
	&.chosen-container-multi .chosen-choices,
	&.chosen-container-single .chosen-choices,
	&.chosen-container-single .chosen-single,
	&.chosen-container-active.chosen-with-drop .chosen-single {
		border: none;
		box-sizing: border-box;
		min-height: 42px;
		padding: 8px 50px 8px 15px;
		min-width:200px;
		box-shadow: none;
		// border: $octonary-border;
		border: $primary-border;
		background: none $form-bg-color;
		font-size: 1rem;
		
		&:first-child:before {
			display: block;
			content: "";
			height: 100%;
			width: 42px;
			background: $arrow-grey-down no-repeat 50% 50% / 22px 12px white;
			border-left: $octonary-border;
			position: absolute;
			right: 0;
			top: 0;
		}
		div b {
			background: none;
			display: none;
		}
	}


	.chosen-drop {
		border: $primary-border;
		border-top: 0;
		background: #fff;
		box-shadow: $box-shadow;
		width: calc(100%);
		font: 1rem/1.1875 $base-font-family;
	}
	
	.chosen-results {
		color: $primary;
		li.active-result {
			padding: 10px;
			&.highlighted {
				background: $sunset_orange;
			}
		}	
	}
}


.postscript-first,
.content-additional {
	.section-row a.chosen-single {
		color:$davys_grey;
		&:hover {
			color:$davys_grey;
		}
	}
}
