table {
	border-collapse: separate;
	border-spacing: 0;
	margin: 0 0 $vert-space;
	width: auto;
	display: block;
	
	caption {
		background:$table-caption-bg-color;
		border-bottom:$table-caption-border;
		color:$table-caption-text-color;
		font: $table-caption-font;
		padding:$vert-space/2 $vert-space*1.5;
		border-left: 3px solid $middle_yellow;
		//box-shadow: 0 0 4px 0 rgba(0,0,0,0.2);
		&:before {
		  content: "";
		  display: block;
		  height: 37px;
		  width: 37px;
		  background: $diamond-yel no-repeat scroll 50% 50%;
		  position: absolute;
		  left: -17px;
			margin-top:10px;
		}
		.date-display-single {
			color:$table-caption-text-color;
		}
	}
	th,
	td {
		padding: 0.3125rem 0.625rem;
		word-wrap: break-word;
		
		@include media($narrow) {
			padding: $table-padding;			
		}
	}
	th {
		background:$table-header-bg-color;
		border-bottom: $table-header-border;
		font-weight: 700;
	}
	td {
		border-bottom: $primary-border;
		line-height: $base-line-height;
		border-left:$table-cell-border;
		border-right:$table-cell-border;
	}
	tr,
	td,
	th {
		vertical-align: middle;
	}
		
	tr.odd {
		background-color:$table-bg-color;
	}
	tr.even {
		background-color:$table-stripe-bg;
	}
	
	tr.odd:hover,
	tr.even:hover {
		background-color: $table-hover-bg-color;
	}
	tr:first-child td {
		border-top: $table-header-border;
	}
	tr:last-child td {
		border-bottom: $table-border;
	}

	
	tbody {
		//background-color: $table-bg-color;
		//text-align:center;


		td {
			border-bottom: 0px;
			border-right: $table-border;

			button {
				display: inline-block;
				font-size: .7em;
				line-height: $base-line-height;
				margin-bottom: .3em;
				margin-right: 0.5em;
				outline: none;
				padding: .3em 1em;
				width: 100%;

				@include media($narrow) {
					margin-bottom: 0;
					width: auto;
				}
			}
		}
	}
}

//CONTENT MANAGEMENT TABLE 
.content-table table {
  view-header {
    font:$card-title-font;
    margin-bottom:20px;
  }
  th {
    a.active {
      color:$true_blue;
      &:hover {
        color:$sunset_orange;
      }
    }
    &.views-field-title {
      width: 40%;
    }
    &.views-field-type {
      width: 20%;
    }
    &.views-field-status {
      width: 5%;
    }
    &.views-field-created,
    &.views-field-changed {
      width: 15%;
    }
  }
  td.active {
    background:transparent
  }
}

//RSS

.rss-table td ul {
    margin-left: -30px;
}