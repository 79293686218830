.node-type-organisation {
	
  article h1 {
    margin: 0;
  }
	h1 .card-title-field,
	.card-geofield {
		display:none;
	}
  aside#sidebar-second {
    span.views-label {
      float: left;
      margin-right: 10px;
    }
    .views-field-field-primary-phone-number,
	.card-address {
      margin-bottom: 1em;
    }
  }
  .content-additional {
    section.block {
      background: $white;
      margin-bottom: 1em;

    }
  }
  /*.card-qa-question {
    padding: 10px 60px 10px 30px;
  }*/

  .card-accreditation ul {
    list-style-type: disc;
    margin-left: 1em;
  }
	
	.view-display-id-child {
		.card-title {
			margin-bottom:15px;
		}
	}
}
.tag-list {
  li.directory-tag {
    margin-bottom: 10px;
    &:before {
      content: "";
    }
  }
}
