.page-search-node {
	section#content {
		background: none;
    h1#page-title {
      padding: 0 20px;
    }
		.search-advanced {
			margin-top: $vert-space;
		}
		.search-result {
			padding: $vert-space $horz-space;
      background: $white;
		}
    #search-form {
      .form-type-textfield {
        float: left;
        clear: left;
        margin-right: 1em;
        input#edit-keys {
          width: calc(174px + 20vw);
        }
        @media (max-width:397px) {
        	margin-bottom: 0;
        }
      }
    }
    h2 {
      clear:both;
    }
    #edit-submit {
    	@include media(398px) {
	      margin-top: 40px;
	    }
    }
    #edit-advanced {
      clear: left;
    }
	}
}
