.full_width_main {
	main {
		padding-top: 0;
		
		.outer-wrapper {
			max-width: 100%;
			
			section#content,
			section#content .content-main {
				padding: 0;
			
				.views-exposed-form {
					background-color: white;
					//padding: 30px 10px;
					padding: 30px;
					
					.views-exposed-widgets {
						max-width: 1600px;
						margin-left: auto;
						margin-right: auto;
					}
				}
				
				#edit-sort-bef-combine-wrapper {
					max-width: 1600px;
					margin-left: auto;
					position: relative;
					margin-right: auto;
					
					.views-widget {
						@include media($narrow) {
							position: absolute;
							right: 257px;
						}
						
						.form-type-select {
							padding: 0 10px;
							margin-top:0;
							@include media($narrow) {
								margin-top: 24px;
								padding: 0;
								width:auto;
								margin-right:20px;
							}
							
							label {
								display: inline-block;
								margin-right: 0.5rem;
							}
							select {
								width: calc(100% - 71px);
								@include media($narrow) {
									width: auto;
								}
							}
						}
					}
				}
				
				.view-filters .view-header,
				.view-content,
				.attachment {
					padding: 30px 15px;
					max-width: 1600px;
					margin-left: auto;
					margin-right: auto;
				}
				.diamond-list {
					.view-content.diamond-header {
						padding: 20px 20px 20px 40px;
					}
					.view-content,
					.attachment {
						padding:0;
					}
				}
				.view-filters {
					margin-bottom: 0;
					
					.view-header {
						padding-bottom: 0;
						
						.view-mode {
							margin-top: 1.125em;
							@include media($narrow) {
								float: right;
								margin-top: -6px;
							}
							
							@media (max-width:720px) {
								float: right;
								margin-top: -6px;
							}
							
							.view-mode-switch {
								border: $primary-border;
								//height: 42px;
								padding: 11px 10px 11px 40px;
								font: 500 1.125rem/1 $base-font-family;
								display: inline-flex;
								@include media($narrow) {
									margin-left: -6px;
								}
								
								&.view-mode-grid {
									background: $ico-grid no-repeat scroll 10px 50% / 23px 24px white;
									
									&.active {
										color: white;	
										background: $ico-grid-active no-repeat scroll 10px 50% / 21px 17px $true_blue;
										border-color: $true_blue;
									}
									&:hover {
										color: white;	
										background: $ico-grid-active no-repeat scroll 10px 50% / 21px 17px $sapphire_blue;
										border-color: $sapphire_blue;
										cursor: pointer;
									}
								}
								&.view-mode-map {
									background: $ico-map no-repeat scroll 10px 50% / 23px 24px white;
									margin-left: -6px;
									
									&.active {
										color: white;	
										background: $ico-map-active no-repeat scroll 10px 50% / 23px 24px $true_blue;
										border-color: $true_blue;
									}
									&:hover {
										color: white;	
										background: $ico-map-active no-repeat scroll 10px 50% / 23px 24px $sapphire_blue;
										border-color: $sapphire_blue;
										cursor: pointer;
									}									
								}
							}
						}
					}
				}
				.view-id-directory_products {
					#edit-sort-bef-combine-wrapper .views-widget {
						position: relative;
						float: right;
						right: 0;
					}
					.view-content {
						clear: both;
					}
				}
			}
		}
	}
	&:not(.page-directory-search) main .outer-wrapper section#content {
		#edit-sort-bef-combine-wrapper .views-widget {
			right: 0;
			.form-item {
				margin-right: 10px;
			}
		}
		.view-header {
			@media (max-width: $narrow) {
				margin-top: 0;
			}
		}
	}
}

