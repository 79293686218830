//@import url(https://fonts.googleapis.com/css?family=Oswald:400,700|Roboto:400,400i,700,700i);
@import url(https://use.typekit.net/fzl7eyo.css);

$base-font-family      		: 'proxima-nova', "Proxima Nova","Open Sans","Gill Sans MT","Gill Sans",Corbel,Arial,sans-serif;
$title-font-family    		: 'ubuntu', "Ubuntu", "Segoe UI", "Roboto", "Oxygen-Sans", "Cantarell", "Helvetica Neue", sans-serif;

$base-font-size						: 16px;
$base-line-height					: 1.5;

$base-font-size-normal		: 18px;

$header-menu-font 				: /*0.875rem*/ 0.778rem/1 $base-font-family;
$header-search-field-font : 0.875rem/1.286 $base-font-family;

$nav-base-font        	 	: 700 1.125rem/1.3 $title-font-family;
$nav-base-font-smaller 		: 700 0.875rem/1.375 $title-font-family;
$nav-sub-base-font        : 600 1.125rem/1.3 $base-font-family;

$breadcrumb-font 					: 600 1rem/1 /*0.889rem/1*/ $base-font-family;

$section-row-title-font 	: 2.125rem/1.14705882352941 $title-font-family; //1.5rem/1.25 $title-font-family; // /* Nugget title */
$section-row-title-font-smaller 	: 500 2rem/1.25 $title-font-family; // /* Nugget title */ 

$side-row-title-font 			: 500 1.625rem/1.3 $title-font-family; // /* Sidebar nugget title */

$card-title-font-family		: $title-font-family;
$card-title-font        	: 500 1.25rem/1.4 $card-title-font-family;
$card-font-family       	: $base-font-family;
$card-more-link-font			: 500 1.625rem/1.4 $card-title-font-family;
$card-feed-link-font			: 500 1.125rem/1.46153846153846 $title-font-family;

$button-link-font 				: 600 $base-font-size/1 $base-font-family;

/* FORMS */
$button-font 							: 600 1.125rem/1 $base-font-family;
