#site-footer {
	.outer-wrapper {
		padding:0 $gutt/2;
		box-sizing: border-box;
	}

	h2 {
		color:$footer-first-text;
		font-weight:normal;
		font-size:1.625rem;
	}

	p {
		font-size:1.125rem;
	}
	ul:not(.contextual-links) {
		padding: 0;
		margin-top:0;

		li {
			list-style: none;
		}
	}

	.card-follow-us-on ul li {
		display: inline-block;
		line-height: 0.8em;
	}

	.footer-first {
		background: $footer-first-bg;
		color: $footer-first-text;
		padding:$vert-space 0;
		@include palette-footer-first-slick-controls;

		.outer-wrapper {
			border-bottom:$footer-first-border;
			padding-bottom:$vert-space/2;
		}

		.card {
			@include palette-footer-first-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-first-card-more-link;
		}
		.card-feed-link {
			@include palette-footer-first-card-feed-link;
		}


		a {
			color:$footer-first-action;
			&:hover {
				color:$footer-first-action-hover;
			}
		}
	}
	.footer-second {
		background: $footer-second-bg;
		color: $footer-second-text;
		padding:$vert-space 0;
		@include palette-footer-second-slick-controls;

		a {
			color:$footer-second-action;
			&:hover {
				color:$footer-second-action-hover;
			}
		}
		.card {
			@include palette-footer-second-cards;
		}
		a.card:hover {
			@include card-hover;
		}
		.card-more-link {
			@include palette-footer-second-card-more-link;
		}
		.card-feed-link {
			@include palette-footer-second-card-feed-link;
		}

		section.block {
			margin-bottom:$vert-space;
			@media screen and (min-width: 45em) {
				margin-bottom:0;
			}
			&:last-child {
				margin-bottom:0;
			}
		}
		.footer-block {
			margin-bottom:$vert-space*2;

			@media screen and (min-width: 45em) {
				margin-bottom:0;
			}
		}

	}
	.footer-third {
		background: $footer-third-bg;
		color: $footer-third-text;
		padding:$vert-space/2 0;
		text-align:center;
		font-size:1.125em;

		a {
			color:$footer-third-action;
			&:hover {
				color:$footer-third-action-hover;
			}
		}
		#manta-ray-media {
			float: none;
			@include media(880px) {
				float: right;
			}
			a {
				color: $action-default;
				&:hover {
					color: $action-hover;
				}
			}
		}
		.block-menu {
			float: none;
			@include media(880px) {
				float: left;
			}
			ul {
				li {
					display: inline-block;
					margin-right: 15px;
					padding-right: 20px;
					border-right: 1px solid $secondary-border-color;
				}
				li.last {
					border-right: none;
					margin-right: 0;
					padding-right: 0;
				}
			}
		}
	}

	.card-links {
		display: inline-block;
		margin-right: 10px;
		text-indent: -9999px;

		a {
			background-color: $card-links-action-bg;
			border-radius: $card-links-border-radius;
			width: $card-links-width;
			height: $card-links-height;
			display:block;
			margin:0 0 10px;
			&:hover {
				opacity: $card-links-hover-opacity;
				border-radius: $card-links-hover-border-radius;
			}
		}

		/* newsletter */
		.newsletter a,
		a[href^="http://eepurl.com"] {
			background: $ico-newsletter-over no-repeat scroll 50% 50% / 22px 22px, $social-bg-default no-repeat scroll 50% 50% / 60px 60px;
			&:hover {
				background: $ico-newsletter-over no-repeat scroll 50% 50% / 22px 22px, $social-bg-hover no-repeat scroll 50% 50% / 60px 60px;
			}
		}

		/* linkedin */
		.linkedin a,
		a[href^="https://www.linkedin.com"],
		a[href^="http://www.linkedin.com"],
		a[href^="https://linkedin.com"],
		a[href^="http://linkedin.com"],
		a[href^="https://uk.linkedin.com"],
		a[href^="http://uk.linkedin.com"] {
			background: $ico-linkedin-white no-repeat scroll 50% 50% / 22px 22px, $social-bg-default no-repeat scroll 50% 50% / 60px 60px;
			&:hover {
				background: $ico-linkedin-white no-repeat scroll 50% 50% / 22px 22px, $social-bg-hover no-repeat scroll 50% 50% / 60px 60px;
			}
		}

		/* twitter */
		.twitter a,
		a[href^="https://www.twitter.com"],
		a[href^="http://www.twitter.com"],
		a[href^="https://twitter.com"],
		a[href^="http://twitter.com"] {
			background: $ico-twitter-white no-repeat scroll 50% 50% / 22px 22px, $social-bg-default no-repeat scroll 50% 50% / 60px 60px;
			&:hover {
				background: $ico-twitter-white no-repeat scroll 50% 50% / 22px 22px, $social-bg-hover no-repeat scroll 50% 50% / 60px 60px;
			}
		}

        /* YouTube */
		.youtube a,
		a[href*="youtube.com"],
		a[href^="https://www.youtube.com"],
		a[href^="http://www.youtube.com"],
		a[href^="https://youtube.com"],
		a[href^="http://youtube.com"] {
			background:$ico-youtube-white no-repeat scroll 50% 50% / 22px 22px, $social-bg-default no-repeat scroll 50% 50% / 60px 60px;
			&:hover {
				background: $ico-youtube-white no-repeat scroll 50% 50% / 22px 22px, $social-bg-default no-repeat scroll 50% 50% / 60px 60px;
			}
		}


		/* facebook */
		.facebook a,
		a[href^="https://www.facebook.com"],
		a[href^="http://www.facebook.com"],
		a[href^="https://facebook.com"],
		a[href^="http://facebook.com"] {
			background: $ico-facebook-white no-repeat scroll 50% 50% / 22px 22px, $social-bg-default no-repeat scroll 50% 50% / 60px 60px;
			&:hover {
				background: $ico-facebook-white no-repeat scroll 50% 50% / 22px 22px, $social-bg-hover no-repeat scroll 50% 50% / 60px 60px;
			}
		}

		/* google+ */
		.g-plus a,
		a[href^="https://www.plus.google.com"],
		a[href^="http://www.plus.google.com"],
		a[href^="https://plus.google.com"],
		a[href^="http://plus.google.com"] {
			background:$ico-gplus-white no-repeat scroll 50% 50% / 22px 22px, $social-bg-default no-repeat scroll 50% 50% / 60px 60px;
			&:hover {
				background: $ico-gplus-white no-repeat scroll 50% 50% / 22px 22px, $social-bg-hover no-repeat scroll 50% 50% / 60px 60px;
			}
		}
	}


	.copyright {
		padding-left: 1em;
	}

	.utility-menu {
		ul {
			text-align: right;
			font-weight: 600;

			li {
				display: inline;
				padding-right: 1em;

				a {
					color: $footer-first-action;
				}
			}
		}
	}
	span.ext {
		display: none;
	}

	.card-original {
		@media (min-width:720px) {
			max-width:165px;
		}
	}
	.card-logo {
		margin-right:10px;
		margin-bottom:10px;
	}

	.view-display-grid .row-max-2 .card-organisations article:nth-child(2n+1) {
		@media (min-width:1200px) {
			clear:none;
		}
		@media (min-width:540px) and (max-width:720px) {
			clear:none;
		}
		@media (min-width:720px) and (max-width:1200px) {
			width:100%;
		}
	}
}
